import { RadioGroup } from '@headlessui/react'
import { CheckCircleIcon } from '@heroicons/react/solid'
import classNames from 'classnames'
import { FC } from 'react'
import { Label } from '../Label'

interface RadioCardOptions {
  id: string
  title: string
  description?: string
}

interface RadioCardProps {
  value?: any
  onChange?: (value: any) => void
  options: RadioCardOptions[]
  label?: string
  disabled?: Boolean
  cols?: number
}

export const RadioCards: FC<RadioCardProps> = ({
  value,
  onChange,
  options = [],
  label,
  disabled,
  cols,
}) => {
  let colsClass

  switch (cols) {
    case 1:
      colsClass = 'sm:grid-cols-1'
      break

    case 2:
      colsClass = 'sm:grid-cols-2'
      break

    case 3:
      colsClass = 'sm:grid-cols-3'
      break

    case 4:
      colsClass = 'sm:grid-cols-4'
      break
    default:
      // If cols wasn't specified, we should calculate the best
      // fit based on the number of options.
      colsClass =
        options.length === 2
          ? 'sm:grid-cols-2'
          : options.length === 4
          ? 'sm:grid-cols-4'
          : 'sm:grid-cols-3'
  }

  return (
    <RadioGroup by="id" value={value} onChange={onChange}>
      {label && <Label className="mb-2">{label}</Label>}
      <div className={`grid grid-cols-1 gap-y-4 ${colsClass} sm:gap-x-4`}>
        {options.map((option) => (
          <RadioGroup.Option
            key={option.id}
            value={option}
            className={({ checked, active }) =>
              classNames(
                checked ? 'border-transparent' : 'border-border',
                {
                  'border-violet ring-2 ring-violet': active,
                  'opacity-70 cursor-not-allowed': disabled,
                  'cursor-pointer': !disabled,
                },
                'relative flex rounded-lg border bg-background-input p-4 shadow-sm focus:outline-none'
              )
            }
          >
            {({ checked, active }) => (
              <>
                <span className="flex flex-1">
                  <span className="flex flex-col">
                    <RadioGroup.Label as="span" className="block text-sm font-medium">
                      {option.title}
                    </RadioGroup.Label>
                    <RadioGroup.Description
                      as="span"
                      className="mt-1 flex items-center text-sm text-gray-500"
                    >
                      {option.description}
                    </RadioGroup.Description>
                  </span>
                </span>
                <CheckCircleIcon
                  className={classNames(!checked ? 'invisible' : '', 'h-5 w-5 text-violet')}
                  aria-hidden="true"
                />
                <span
                  data-test-id={option.id}
                  className={classNames(
                    active ? 'border' : 'border-2',
                    checked ? 'border-violet' : 'border-transparent',
                    'pointer-events-none absolute -inset-px rounded-lg'
                  )}
                  aria-hidden="true"
                />
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  )
}
