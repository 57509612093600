import { Modal, ModalButtons } from 'ui/Modal'
import { Button } from 'ui/components/Button'
import { useState } from 'react'
import { Form, Formik } from 'formik'
import { ConnectedField as BaseConnectedField } from 'components/ConnectedField'
import { useNotificationContext, Notification } from 'ui/components/Notification'
import { ErrorAlert } from 'components/ErrorAlert'
import { ErrorProvider } from 'contexts/Error'
import { useInfiniteMembershipCreditRules } from 'api/MembershipCreditRule'
import { Toggle } from 'ui/components/Form/Toggle'
import { FormikDateInput } from 'components/DateInput'
import { useCreateCustomerCredit } from 'api/CustomerCredits'
import { PlusIcon } from '@heroicons/react/solid'
import { Select } from 'ui/components/Form/Select'
import { useSiteConfig } from 'hooks/UseSiteConfig'

const ConnectedField = BaseConnectedField as any

export const IssueCreditModal = ({ clientId }) => {
  const [show, setShow] = useState(false)
  const notification = useNotificationContext()
  const [hasExpiry, setHasExpiry] = useState(false)
  const { site = {} } = useSiteConfig()
  const { brand_id: brandId } = site

  const initialValues = {
    coupon_id: null,
    coupon: {},
    expiry_date: null,
    customer_id: clientId,
  }

  const {
    hasNextPage,
    fetchNextPage,
    data,
    isFetchingNextPage,
    isLoading: isLoadingRules,
  } = useInfiniteMembershipCreditRules({
    brandId,
  })

  const { isLoading, isError, error, mutate } = useCreateCustomerCredit(clientId)

  const create = (vals) => {
    vals.coupon_id = vals.coupon.value

    mutate(vals, {
      onSuccess: () => {
        setShow(false)
        notification.notify(
          <Notification
            title="Credit issued"
            description="The credit was successfully issued"
            variant="success"
            autoDismiss
          />
        )
      },
    })
  }

  return (
    <>
      <Button label="Issue" leadingIcon={PlusIcon} onClick={() => setShow(true)} />
      <Modal title="Issue credit" isOpen={show} onClose={() => setShow(false)}>
        <Formik initialValues={initialValues} onSubmit={create}>
          {({ submitForm, setFieldValue, values }) => (
            <Form>
              {isError && (
                <ErrorProvider error={error}>
                  <ErrorAlert />
                </ErrorProvider>
              )}

              {!isLoadingRules && data.pages[0] && data.pages[0].total === 0 && (
                <div className="text-gray-500 text-sm">
                  You can't issue a credit because no credit rules have been set up yet.
                </div>
              )}

              {!isLoadingRules && data.pages[0].total > 0 && (
                <div className="grid grid-cols-2 gap-4 gap-x-6">
                  <div className="col-span-2">
                    <Select
                      isLoadingMore={isFetchingNextPage}
                      loadMore={() => fetchNextPage()}
                      hasMore={hasNextPage}
                      options={data.pages
                        .map((page) =>
                          page.data.map((creditRule) => ({
                            value: creditRule.coupon_id,
                            name: creditRule.coupon_name,
                            description: creditRule.membership_type.name,
                          }))
                        )
                        .flat()}
                      selected={values.coupon}
                      placeholder="Pick a credit to issue"
                      onChange={(val) => setFieldValue('coupon', val)}
                    />

                    <p className="text-sm mt-2 text-gray-500">
                      Select which credit type should be issued. These are configured in the
                      Membership settings.
                    </p>
                  </div>
                  <div className="col-span-2">
                    <Toggle value={hasExpiry} onChange={setHasExpiry} label="Set an expiry date" />
                  </div>
                  {hasExpiry && (
                    <div className="col-span-2">
                      <ConnectedField
                        component={FormikDateInput}
                        name="expiry_date"
                        label="Expiry date"
                      />
                    </div>
                  )}
                </div>
              )}
              <ModalButtons>
                {!isLoadingRules && data.pages[0] && data.pages[0].total > 0 && (
                  <Button
                    variant="primary"
                    label="Issue credit"
                    onClick={submitForm}
                    loading={isLoading}
                  />
                )}
                <Button label="Cancel" onClick={() => setShow(false)} />
              </ModalButtons>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  )
}
