import { ExclamationIcon } from '@heroicons/react/outline'
import { lastEventId } from '@sentry/browser'
import { Modal, ModalButtons } from 'ui/Modal'
import { Button } from 'ui/components/Button'
import { CopyableCode } from 'ui/components/CopyableCode'

export const ErrorFallback = ({ error }) => {
  const reload = () => {
    window.location.reload()
  }

  const errorReference = lastEventId()

  return (
    <Modal isOpen onClose={reload} hideCloseButton prompt icon={ExclamationIcon} title="Oh no!">
      <div className="text-gray-500 text-sm space-y-2">
        <p>It looks like something unexpected has happened.</p>
        <p>We've sent an alert to our team to investigate.</p>
        <div>
          The error reference code is <CopyableCode code={errorReference} />
        </div>
        <p>Try reloading the app.</p>

        {import.meta.env.VITE_APP_PROJECT_ENVIRONMENT === 'development' && (
          <pre className="bg-red-600 whitespace-normal text-white font-mono text-sm font-medium p-3 rounded-md">
            {error.message}
          </pre>
        )}
      </div>

      <ModalButtons prompt>
        <Button variant="primary" onClick={() => reload()} label="Reload" fullWidth />
        <Button as="a" href="/dashboards" label="Back home" fullWidth />
      </ModalButtons>
    </Modal>
  )
}

export default ErrorFallback
