import { useRegisterSW } from 'virtual:pwa-register/react'

export const PromptForUpdate = () => {
  const {
    needRefresh: [needRefresh],
    updateServiceWorker,
  } = useRegisterSW({
    onRegisteredSW(_, registration) {
      setInterval(
        () => {
          registration.update()
        },
        60 * 5 * 1000
      ) // every 5 minutes
    },
  })

  if (!needRefresh) {
    return null
  }

  return (
    <div className="z-10 absolute bottom-0 inset-x-0 text-center m-3 pointer-events-none">
      <button
        className="bg-violet z-10 rounded-xl shadow-xl p-3 inline-flex text-white text-sm font-medium mx-auto transition hover:scale-[98%] pointer-events-auto"
        onClick={() => updateServiceWorker(true)}
      >
        A new version of the app is available.&nbsp; Click here to update
      </button>
    </div>
  )
}
