import { useState } from 'react'
import { Input } from 'ui/components/Form/Input'
import { Button } from 'ui/components/Button'
import { Modal, ModalButtons } from 'ui/Modal'
import {
  ApiErrorNotification,
  Notification,
  useNotificationContext,
} from 'ui/components/Notification'
import { PlusCircleIcon } from '@heroicons/react/solid'
import { useAddOrderGuest } from 'api/OrderGuests'
import { useCustomerSearch } from 'api/Customers'
import { PaginatedMiniList } from 'ui/components/PaginatedMiniList'
import { ResourceListItem } from 'ui/components/ResourceListItem'
import { LoadingSpinner, SpinnerSize } from 'ui/components/LoadingSpinner'
import { useGetOrder } from 'api/Orders'
import { Form, Formik } from 'formik'
import { ConnectedField } from 'components/ConnectedField'
import { useCurrentSiteId } from 'contexts/SiteConfig'
import { useGetShopConfig } from 'api/ShopConfig'

export const AddOrderGuestOverlay = ({ orderId, isOpen = false, onClose }) => {
  const notification = useNotificationContext()
  const { data: { data: order = {} } = {} } = useGetOrder(orderId)
  const [customers, setCustomers] = useState([])
  const [isAddingByName, setIsAddingByName] = useState(false)
  const [isAddingCustomer, setIsAddingCustomer] = useState(null)
  const siteId = useCurrentSiteId()

  const { first_name: firstName = '', last_name: lastName, guests = [] } = order

  const { mutate: fetchCustomers, isSuccess, isLoading: isSearching } = useCustomerSearch()

  const { isLoading, data: { data: shopConfig = {} } = {} } = useGetShopConfig(siteId, {
    enabled: !!siteId,
  })

  const separateNameValuesEnforced = shopConfig.separate_name_values_enforced

  const { mutateAsync, mutate } = useAddOrderGuest()

  const setLeadBookerGuest = async () => {
    const hasCustomer = firstName || lastName

    if (guests.length === 0 && hasCustomer) {
      const customerFirstName = hasCustomer ? firstName : 'Guest'
      const customerLastName = hasCustomer ? lastName : '1'

      await mutateAsync([
        orderId,
        { first_name: customerFirstName, last_name: customerLastName, is_lead_booker: true },
      ])
    }
  }

  const handleAddGuestByName = async (values) => {
    setIsAddingByName(true)
    await setLeadBookerGuest()

    if (separateNameValuesEnforced) {
      addGuest({
        first_name: values.first_name,
        last_name: values.last_name,
      })
    } else {
      addGuest({ name: values.guest_name })
    }
  }

  const handleAddCustomerAsGuest = async (customer) => {
    setIsAddingCustomer(customer.id)
    await setLeadBookerGuest()
    addGuest({
      first_name: customer.first_name,
      last_name: customer.last_name,
      customer_id: customer.id,
    })
  }

  const addGuest = (guest) => {
    mutate([orderId, guest], {
      onSuccess: ({ data: addedGuest }) => {
        notification.notify(
          <Notification
            title="Guest added"
            description="The guest was added successfully"
            variant="success"
            autoDismiss
          />
        )

        onClose(addedGuest)
      },
      onError: (error) => {
        notification.notify(<ApiErrorNotification error={error} />)
      },
      onSettled: () => {
        setIsAddingByName(false)
        setIsAddingCustomer(null)
      },
    })
  }

  const handleCustomerSearch = (values) => {
    fetchCustomers(values.search_term, {
      onSuccess: (response) => {
        setCustomers(response.data)
      },
    })
  }

  if (!isOpen) return null

  return (
    <>
      <div className="text-gray-600 text-sm">
        You can add a guest with just a name, or search for an existing customer to add them as a
        guest for this order.
      </div>
      <div className="mt-3 grid gap-4">
        <Formik
          onSubmit={handleAddGuestByName}
          initialValues={
            separateNameValuesEnforced
              ? { first_name: '', last_name: '', guest_name: '' }
              : { guest_name: '' }
          }
        >
          <Form>
            {!separateNameValuesEnforced && (
              <div className="flex gap-2 items-end">
                <div className="flex-grow">
                  <ConnectedField
                    name="guest_name"
                    type="text"
                    component={Input}
                    placeholder="Jane Smith"
                    label="Guest name"
                  />
                </div>
                <div className="flex-shrink-0">
                  <Button label="Add" type="submit" loading={isAddingByName} />
                </div>
              </div>
            )}
            {separateNameValuesEnforced && (
              <div className="flex gap-1 items-end">
                <div className="">
                  <ConnectedField
                    name="first_name"
                    type="text"
                    component={Input}
                    placeholder="Jane"
                    label="Guest first name"
                  />
                </div>
                <div className="pl-4">
                  <ConnectedField
                    name="last_name"
                    type="text"
                    component={Input}
                    placeholder="Smith"
                    label="Guest last name"
                  />
                </div>
                <div className="ml-2 mt-5 px-2">
                  <Button
                    className="flex-grow"
                    label="Add"
                    type="submit"
                    loading={isAddingByName}
                  />
                </div>
              </div>
            )}
          </Form>
        </Formik>
        <div className="border-t border-gray-200"></div>
        <Formik onSubmit={handleCustomerSearch} initialValues={{ search_term: '' }}>
          <Form>
            <div>
              <div className="flex gap-2 items-end">
                <div className="flex-grow">
                  <ConnectedField
                    name="search_term"
                    type="text"
                    component={Input}
                    placeholder="Search"
                    label="Customer search"
                  />
                </div>
                <div className="flex-shrink-0">
                  <Button label="Search" type="submit" loading={isSearching} />
                </div>
              </div>
            </div>
          </Form>
        </Formik>
        {(isSuccess || isSearching) && (
          <PaginatedMiniList
            isLoading={isSearching}
            emptyMessage="No customers found"
            rows={customers.map((customer) => (
              <ResourceListItem
                key={customer.id}
                compact
                className="cursor-pointer hover:bg-gray-50"
                title={
                  <div className="flex">
                    <div className="flex-grow">{customer.full_name}</div>
                    {isAddingCustomer === customer.id && (
                      <div className="ml-2">
                        <LoadingSpinner size={SpinnerSize.sm} variant="info" />
                      </div>
                    )}
                  </div>
                }
                subtitle={customer.email}
                onClick={() => handleAddCustomerAsGuest(customer)}
              />
            ))}
          />
        )}
      </div>
      <ModalButtons>
        <Button variant="tertiary" onClick={onClose} label="Close" />
      </ModalButtons>
    </>
  )
}

export const AddGuestOverlayButton = ({ variant = 'ghost', orderId, onClose = undefined }) => {
  const [isOpen, setIsOpen] = useState(false)

  const handleClose = (addedGuest) => {
    if (typeof onClose === 'function') {
      onClose(addedGuest)
    }

    setIsOpen(false)
  }

  return (
    <>
      <Button
        variant={variant}
        leadingIcon={PlusCircleIcon}
        label="Add guest"
        onClick={() => setIsOpen(true)}
      />
      <Modal isOpen={isOpen} title="Add guest" onClose={handleClose}>
        <AddOrderGuestOverlay orderId={orderId} isOpen={isOpen} onClose={handleClose} />
      </Modal>
    </>
  )
}
