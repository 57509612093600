import styled, { css, keyframes } from '@xstyled/styled-components'
import { Box } from '@trybeapp/ui.box'

/**
 * @deprecated
 */
export const SpinnerOverlay = ({ backgroundColor = 'pageBackground', color = 'primary.900' }) => {
  return (
    <Box
      top={0}
      bottom={0}
      left={0}
      right={0}
      position="absolute"
      display="flex"
      className="bg-background"
      alignItems="center"
      justifyContent="center"
      zIndex="2"
    >
      <Box position="relative">
        <Outer borderTopColor={color} borderRightColor={color} />
        <Middle borderTopColor={color} borderRightColor={color} />
        <Inner borderTopColor={color} borderRightColor={color} />
      </Box>
    </Box>
  )
}

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

const spinnerStyles = css`
  border: 3px solid transparent;

  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
`
const Outer = styled(Box)(
  () => css`
    ${spinnerStyles}
    width: 3.5em;
    height: 3.5em;
    margin-left: -1.75em;
    margin-top: -1.75em;
    animation: ${spin} 2s linear infinite;
  `
)

const Middle = styled(Box)(
  () => css`
    ${spinnerStyles}
    width: 2.1em;
    height: 2.1em;
    margin-left: -1.05em;
    margin-top: -1.05em;
    animation: ${spin} 1.75s linear reverse infinite;
  `
)

const Inner = styled(Box)(
  () => css`
    ${spinnerStyles}
    width: 0.8em;
    height: 0.8em;
    margin-left: -0.4em;
    margin-top: -0.4em;
    animation: ${spin} 1.5s linear infinite;
  `
)
