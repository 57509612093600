import { useState } from 'react'
import pluralize from 'pluralize'
import { useParams } from 'react-router-dom'
import { Modal, ModalButtons } from 'ui/Modal'
import { useEditOrderItem } from 'api/OrderItems'
import { map } from 'lodash'
import { Button } from 'ui/components/Button'
import {
  ApiErrorNotification,
  Notification,
  useNotificationContext,
} from 'ui/components/Notification'
import { useOffering } from 'hooks/UseOffering'
import { GuestsSelector } from 'components/GuestsSelector/GuestsSelector'

export const EditItemGuestsOverlay = ({ onCancel, isOpen = false, item }) => {
  if (!isOpen) {
    return null
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onCancel}
      title={`Change ${pluralize('guest', item.guests.length)}`}
    >
      <EditForm onCancel={onCancel} item={item} />
    </Modal>
  )
}

const EditForm = ({ onCancel, item }) => {
  const { orderId } = useParams()
  const notification = useNotificationContext()
  const { mutate, isLoading: isUpdating } = useEditOrderItem()
  const [guestIds, setGuestIds] = useState(map(item.guests, 'id'))
  const { offering, isLoading: isLoadingOffering } = useOffering(item.type_id, item.item_type)

  if (isLoadingOffering) {
    return (
      <div className="flex flex-col space-y-2 mt-4 animate-pulse">
        <div className="h-10 w-full bg-gray-200 rounded-md" />
      </div>
    )
  }

  const minGuests = offering.hasOwnProperty('min_guests') ? offering.min_guests : item.guests.length
  const maxGuests = offering.hasOwnProperty('max_guests') ? offering.max_guests : item.guests.length

  const handleUpdate = () => {
    mutate([orderId, item.id, { guest_ids: guestIds }], {
      onSuccess: () => {
        notification.notify(
          <Notification
            title="Guests updated"
            description="The guests for this item were updated"
            variant="success"
            autoDismiss
          />
        )
        onCancel()
      },
      onError: (error: any) => {
        notification.notify(<ApiErrorNotification error={error} />)
      },
    })
  }

  const handleChange = (updatedIds) => {
    setGuestIds(updatedIds)
  }

  return (
    <>
      <div className="flex flex-col space-y-2 max-h-80 overflow-auto">
        <GuestsSelector onChange={handleChange} value={guestIds} max={maxGuests} />
      </div>
      <ModalButtons>
        <Button
          label="Update"
          variant="primary"
          onClick={handleUpdate}
          loading={isUpdating}
          disabled={guestIds.length < minGuests || (maxGuests && guestIds.length > maxGuests)}
        />
        <Button label="Cancel" variant="ghost" ml="auto" onClick={onCancel} />
      </ModalButtons>
    </>
  )
}
