import { Fragment, useState } from 'react'
import { BellIcon as BellIconOutline } from '@heroicons/react/outline'

import { Dialog, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/solid'
import { useGetGroupedNotifications } from 'api/UserNotifications'
import { GroupedNotification } from './components/GroupedNotification'
import { LinkWithPreload } from 'utilities/PreloadLinks'
import { ArrowNarrowRightIcon } from '@heroicons/react/outline'
import { GlobalHeaderButton } from 'ui/components/GlobalHeaderButton'
import { useAnalytics } from 'utilities/analytics'

export const NotificationsSlideover = () => {
  const [open, setOpen] = useState(false)
  const { fireEvent } = useAnalytics()

  const { data: { data: notifications = [], meta = {} } = {} } = useGetGroupedNotifications({
    refetchInterval: 1000 * 60 * 5, // Every 5 minutes
  })

  const handleOpen = () => {
    fireEvent('notifications_opened')
    setOpen(true)
  }

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="fixed inset-0 overflow-hidden z-50" onClose={setOpen}>
          <div className="absolute inset-0 overflow-hidden">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity backdrop-filter backdrop-blur" />
            </Transition.Child>

            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <div className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                    <div className="px-4 sm:px-6">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className="text-lg font-medium text-gray-900">
                          Notifications
                        </Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-grape-500 focus:ring-offset-2"
                            onClick={() => setOpen(false)}
                          >
                            <span className="sr-only">Close panel</span>
                            <XIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="relative mt-3 flex-1">
                      <div className="absolute inset-0">
                        <div className="px-4 sm:px-6 mb-3">
                          <LinkWithPreload
                            to={'/notifications'}
                            className="text-sm text-gray-700"
                            onClick={() => setOpen(false)}
                          >
                            View all notifications
                            <ArrowNarrowRightIcon className="w-4 h-4 text-gray-700 ml-2" />
                          </LinkWithPreload>
                        </div>

                        {notifications.length > 0 ? (
                          <ul className="divide-y divide-gray-200 border-y border-gray-200">
                            {notifications.map((notification) => (
                              <GroupedNotification
                                setOpen={setOpen}
                                groupedNotification={notification}
                                key={`${notification.type}_${notification.read}`}
                              />
                            ))}
                          </ul>
                        ) : (
                          <div className="text-center py-6 bg-gray-50 border-y border-gray-200">
                            <BellIconOutline className="mx-auto h-12 w-12 text-gray-400" />
                            <h3 className="mt-2 text-sm font-medium text-gray-900">
                              No incomplete notifications
                            </h3>
                            <p className="mt-1 text-sm text-gray-500">Check back here later</p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      <div>
        <GlobalHeaderButton
          label="View notifications"
          hideLabel
          onClick={() => handleOpen()}
          leadingIcon={BellIconOutline}
        />
        {meta.total_unread > 0 && (
          <>
            <div className="w-3 h-3 absolute bg-red-400 group-hover:bg-red-300 rounded-full top-0 right-0 animate-ping" />
            <div className="border-2 border-purple w-3 h-3 absolute bg-red-400 group-hover:bg-red-300 rounded-full top-0 right-0" />
          </>
        )}
      </div>
    </>
  )
}
