import { useEffect, useState, useMemo, EffectCallback, forwardRef } from 'react'
import { Switch } from '@headlessui/react'
import { Spinner } from 'ui/components/Spinner'
import { CheckIcon } from '@heroicons/react/outline'
import { classNames } from 'ui/utilities'
import { createEvent } from 'utilities/createEvent'
import { Text } from '../Text'
import { Label } from './Label'

interface ToggleProps {
  /** The label for the toggle */
  label?: string
  /** An optional weight for the label */
  labelWeight?: string
  /** An optional description for the toggle */
  description?: string | string[]
  /** The value for the toggle */
  value?: any
  /** Fired when the input is changed */
  onChange?: (checked: boolean) => void
  /** Whether the checkbox is in a loading state */
  loading?: boolean
  /** Whether the checkbox is in a success state */
  success?: boolean
  /** Whether the checkbox is in a disabled state */
  disabled?: boolean
}

export const Toggle: React.FC<ToggleProps> = forwardRef(
  (
    {
      label,
      labelWeight = 'medium',
      description = [],
      value,
      onChange,
      loading,
      success,
      disabled = false,
    },
    ref
  ) => {
    const [displaySuccess, setDisplaySuccess] = useState(false)

    useEffect((): ReturnType<EffectCallback> => {
      if (success) {
        setDisplaySuccess(true)

        const timeout = setTimeout(() => {
          setDisplaySuccess(false)
        }, 3000)

        return () => clearTimeout(timeout)
      }
    }, [success])

    const hasIndicators = useMemo(() => {
      return typeof loading !== 'undefined' || typeof success !== 'undefined'
    }, [loading, success])

    return (
      <Switch.Group as="div">
        <div className="flex items-center">
          <Switch
            checked={value}
            onChange={onChange}
            disabled={disabled}
            className={classNames(
              value ? 'bg-violet' : 'bg-gray-200',
              'disabled:opacity-50 disabled:cursor-not-allowed relative inline-flex flex-shrink-0 h-5 w-9 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-violet'
            )}
          >
            <span
              aria-hidden="true"
              className={classNames(
                value ? 'translate-x-4' : 'translate-x-0',
                'pointer-events-none inline-block h-4 w-4 rounded-full bg-white shadow-lg transform ring-0 transition ease-in-out duration-200'
              )}
            />
          </Switch>
          {label && (
            <Switch.Label as={Label} className="ml-3">
              {label}
            </Switch.Label>
          )}
          {hasIndicators && (
            <div className="w-8">
              {loading && (
                <div className="-mt-1 -mb-2 ml-3">
                  <Spinner variant="info" />
                </div>
              )}
              {!loading && displaySuccess && (
                <span aria-label="Success">
                  <span className="sr-only">The toggle was successfully saved</span>
                  <CheckIcon
                    aria-hidden="true"
                    className="h-5 w-5 inline-block ml-3 text-green-700"
                  />
                </span>
              )}
            </div>
          )}
        </div>
        {description.length > 0 && (
          <Switch.Description as="div" className="text-sm text-gray-500 mt-2 space-y-2">
            {typeof description === 'string' && description}
            {Array.isArray(description) &&
              description.map((line, index) => (
                <Text type="caption" key={index}>
                  {line}
                </Text>
              ))}
          </Switch.Description>
        )}
      </Switch.Group>
    )
  }
)

export const FormikToggle = forwardRef<any, any>(
  ({ onChange: parentOnChange, ...props }: any, ref: any) => {
    const handleOnChange = (value) => {
      parentOnChange(createEvent({ value: value || '', name: props.name }))
    }

    return <Toggle onChange={handleOnChange} ref={ref} {...props} />
  }
)
