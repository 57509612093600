import { classNames } from 'ui/utilities'

export const Card = ({ children, withPadding = true, className = '' }) => (
  <div
    className={classNames(
      className,
      (withPadding ? 'p-6 ' : '') +
        ' text-sm rounded-lg transition-colors bg-background border border-border shadow-sm space-y-2'
    )}
  >
    {children}
  </div>
)
