import { useChannel, useEvent } from '@harelpls/use-pusher'
import { Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import { useGetCustomer } from 'api/Customers'
import { useGantnerDevice } from 'api/GantnerDevices'
import { useGetNotificationPreferences } from 'api/GantnerUsageNotifications'
import { FoundClient } from 'components/BarcodeListener/ScannedBarcodeToast'
import { useCurrentSiteId } from 'contexts/SiteConfig'
import { Fragment, useState } from 'react'
import { Badge } from 'ui/components/Badge'
import { useNotificationContext } from 'ui/components/Notification'
import { withCustomerPusher } from 'utilities/Pusher/Config'

export const GantnerUsageNotification = () => {
  const { isSuccess, data } = useGetNotificationPreferences()

  if (!isSuccess || (isSuccess && data?.data?.type === null)) {
    return null
  }

  return <GantnerUsageNotificationInner preferences={data?.data} />
}

interface GantnerDeviceUsedResponse {
  device_id: string
  customer_id?: string
  was_granted_access: boolean
}

const CustomerToast = ({ deviceId, customerId, wasGrantedAccess }) => {
  const siteId = useCurrentSiteId()
  const { isLoading: isLoadingCustomer, data: { data: customer } = {} } = useGetCustomer(customerId)

  const { isLoading: isLoadingDevice, data: { data: device } = {} } = useGantnerDevice(
    siteId,
    deviceId
  )

  const [show, setShow] = useState(true)

  return (
    <Transition
      show={show}
      as={Fragment}
      enter="transition ease-out duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition ease-in duration-300"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="max-w-sm relative w-full bg-white shadow-lg rounded-xl pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
        <div className="flex items-center space-x-2 border-b border-gray-200 p-4">
          <div className="flex-shrink-0">
            {wasGrantedAccess && <Badge variant="success" label="Granted" />}
            {!wasGrantedAccess && <Badge variant="danger" label="Denied" />}
          </div>
          <div className="flex-1">
            {isLoadingDevice && <div className="text-sm text-gray-500">Loading device...</div>}
            {!isLoadingDevice && device && (
              <div className="text-sm text-gray-500">{device.name}</div>
            )}
          </div>
          <div className="flex-shrink-0">
            <button
              className="bg-white rounded-xl inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-violet"
              onClick={() => setShow(false)}
            >
              <span className="sr-only">Close</span>
              <XIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        </div>

        <div className="p-4">
          {isLoadingCustomer && <div className="text-sm text-gray-500">Loading customer...</div>}
          {!isLoadingCustomer && customer && (
            <FoundClient client={customer} withAutoCheckIn={false} onHide={() => setShow(false)} />
          )}
        </div>
      </div>
    </Transition>
  )
}

const GantnerUsageNotificationInner = withCustomerPusher((props) => {
  const siteId = useCurrentSiteId()
  const channel = useChannel(`GantnerDeviceUsed-${siteId}`)
  const notifications = useNotificationContext()

  const shouldNotify = (preferences, deviceId, wasGrantedAccess) => {
    if (preferences.type === 'access_granted_only' && !wasGrantedAccess) {
      return false
    }

    if (preferences.type === 'access_denied_only' && wasGrantedAccess) {
      return false
    }

    if (preferences.type === 'all_notifications') {
      return true
    }

    if (preferences.devices === null || preferences.devices.length === 0) {
      return true
    }

    if (preferences.devices.includes(deviceId)) {
      return true
    }

    return false
  }

  useEvent(channel, 'GantnerDeviceUsed', (resp: GantnerDeviceUsedResponse) => {
    if (!shouldNotify(props.preferences, resp.device_id, resp.was_granted_access)) {
      return
    }

    notifications.notify(
      <CustomerToast
        customerId={resp.customer_id}
        deviceId={resp.device_id}
        wasGrantedAccess={resp.was_granted_access}
      />
    )
  })

  return null
})
