import { Modal, ModalButtons } from 'ui/Modal'
import { Button } from 'ui/components/Button'
import { useGetApplicableOfferingsForCouponCode } from 'api/CouponCodes'
import { MiniList } from 'ui/components/MiniList'
import { ResourceListItem } from 'ui/components/ResourceListItem'
import { Badge } from 'ui/components/Badge'
import { sentenceCase } from 'change-case'
import {
  TYPE_APPOINTMENT,
  TYPE_AREA_BOOKING,
  TYPE_PACKAGE,
  TYPE_PRODUCT,
  TYPE_SESSION,
} from 'constants/offeringTypes'
import { useHistory } from 'react-router-dom'
import { Money } from 'ui/components/Money'
import { useCurrentSite } from 'contexts/SiteConfig'
import { useCoupon } from 'api/Coupon'

export const ApplicableOfferingsForCouponModal = ({
  hide,
  couponId = null,
  code = null,
  isOpen = false,
}) => {
  const history = useHistory()
  const { currency: siteCurrency } = useCurrentSite()

  const {
    data: { data: codeDiscount = {} } = {},
    isLoading: isLoadingCode,
    isError: isCodeError,
  } = useGetApplicableOfferingsForCouponCode(code, {
    enabled: code !== null,
  })

  const {
    data: { data: { offering_discounts: offeringDiscounts = [] } = {} } = {},
    isLoading: isLoadingCoupon,
    isError: isCouponError,
  } = useCoupon(couponId, {
    enabled: couponId !== null,
  })

  const isLoading = isLoadingCode || isLoadingCoupon
  const isError = isCodeError || isCouponError

  const {
    offerings = [],
    discount_type: discountType = '',
    percentage = '',
    fixed_amount: fixedAmount = '',
  } = offeringDiscounts[0] ?? codeDiscount

  const onOfferingClick = ({ offering_id: id, offering_type: type }) => {
    switch (type) {
      case TYPE_APPOINTMENT:
        return history.push(`/settings/offerings/appointment-types/${id}`)

      case TYPE_AREA_BOOKING:
        return history.push(`/settings/offerings/area-booking-types/${id}`)

      case TYPE_PRODUCT:
        return history.push(`/settings/offerings/retail-products/${id}`)

      case TYPE_SESSION:
        return history.push(`/settings/offerings/session-types/${id}`)

      case TYPE_PACKAGE:
        return history.push(`/settings/offerings/package-types/${id}`)
    }
  }

  const discountString = () => {
    switch (discountType) {
      case 'percentage':
        return `${percentage}%`
      case 'fixed':
        return (
          <span>
            fixed <Money amount={fixedAmount} currency={siteCurrency} />
          </span>
        )
    }
  }

  return (
    <>
      <Modal size="sm" title="Applicable offerings" isOpen={isOpen} onClose={() => hide()}>
        <div className="my-4 text-sm text-gray-500">
          <p>
            This credit provides a{' '}
            <strong className="font-medium text-gray-700">{discountString()}</strong> discount to
            the offerings below.
          </p>
        </div>

        <MiniList
          isLoading={isLoading}
          isError={isError}
          emptyMessage="No applicable offerings found."
          rows={offerings.map((offering, i) => (
            <ResourceListItem
              key={i}
              title={offering.offering_name}
              badge={<Badge variant="info" label={sentenceCase(offering.offering_type)} />}
              onClick={() => onOfferingClick(offering)}
              showDisclosureArrow={true}
              compact
            />
          ))}
        />
        <ModalButtons>
          <Button label="Close" onClick={() => hide()} />
        </ModalButtons>
      </Modal>
    </>
  )
}
