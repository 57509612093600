import { Fragment, useCallback, useEffect, useMemo, useState } from 'react'
import { DateTime, Info as DateInfo } from 'luxon'
import {
  BellIcon,
  BriefcaseIcon,
  ShoppingBagIcon as ShoppingBagIconSolid,
  ArchiveIcon as ArchiveIconSolid,
  LogoutIcon,
  MapIcon,
  PlusIcon,
  SearchIcon,
  UserIcon,
  UsersIcon,
  ClipboardCopyIcon,
} from '@heroicons/react/solid'
import {
  CalendarIcon,
  CogIcon,
  ChevronRightIcon,
  PresentationChartLineIcon,
  ChartBarIcon,
  ShoppingBagIcon,
  QuestionMarkCircleIcon,
  MailIcon,
  TicketIcon,
  CubeIcon,
} from '@heroicons/react/outline'
import { Transition, Combobox, Dialog } from '@headlessui/react'
import classNames from 'classnames'
import { LoadingSpinner, SpinnerSize } from 'ui/components/LoadingSpinner'
import { useAddOrder, useGetOrderByRef } from 'api/Orders'
import { SettingsDirectoryRoute, useRoutes as useSettingsRoutes } from 'routes/SettingsRoutes'
import { ReportsDirectoryRoute, useRoutes as useReportsRoutes } from 'screens/Reports/Reports'
import { useRoutes as usePractitionerAvailabilityRoutes } from 'routes/PractitionerAvailabilityCalendarRoutes'
import { useRoutes as useInventoryRoutes } from 'routes/InventoryRoutes'
import { useRoutes as useClientsRoutes } from 'routes/ClientsRoutes'
import { EmailsDirectoryRoute, useRoutes as useEmailsRoutes } from 'routes/EmailsRoutes'
import { useRoutes as useCalendarRoutes } from 'routes/CalendarRoutes'
import { useRoutes as useOrdersRoutes } from 'routes/OrdersRoutes'
import { useRoutes as useDashboardRoutes } from 'screens/Dashboards/Dashboards'
import { useRoutes as useVouchersRoutes } from 'routes/VouchersRoutes'
import { useRoutes as useVisitRoutes } from 'routes/VisitsRoutes'
import { sentenceCase } from 'change-case'
import { useHistory } from 'react-router-dom'
import { useNotificationContext, Notification } from 'ui/components/Notification'
import { useUser } from 'hooks/UseUser'
import { useActiveFeatureFlags, useCurrentSiteId } from 'contexts/SiteConfig'
import { StringParam, useQueryParam, withDefault } from 'use-query-params'
import { useAuth } from 'react-oidc-context'
import cookie from 'react-cookies'
import { Dropdown, DropdownItem } from './Dropdown'
import { AddClientOverlay } from 'components/ClientList/AddClientOverlay'
import { NotificationsSlideover } from 'components/NotificationsSlideover'
import { GlobalHeaderButton } from './GlobalHeaderButton'
import { MobileSidebar as NewMobileSidebar } from 'layouts/SidebarLayout/NewSidebar'
import { useSidebar } from 'layouts/SidebarLayout/contexts'
import { KeyCommand } from './KeyCommand'
import { Breadcrumb } from './ScreenLayout'
import { useCheckPermissions } from 'routes'
import { useCurrentUserCan } from 'hooks/UseCurrentUserCan'
import { CUSTOMERS_MANAGE, RESERVATIONS_MANAGE } from 'constants/permissions'
import { AddVisitOverlay } from 'screens/Visits/AddVisitOverlay'
import { Avatar } from './Avatar'
import { TYPE_PRODUCT } from 'constants/offeringTypes'
import { useIsInternalUser } from 'components/InternalOnlyBox'
import { useOrganisationId } from 'contexts/OrganisationConfig'
import { useSiteConfig } from 'hooks/UseSiteConfig'
import { useAnalytics } from 'utilities/analytics'

export const GlobalHeader = () => {
  const { previousPages, title } = useSidebar()

  return (
    <div className="inset-0 z-40 bg-background border-b border-border lg:static lg:overflow-y-visible">
      <div className="mx-auto px-4">
        <div className="relative flex justify-items-stretch py-2">
          <div className="md:hidden">
            <NewMobileSidebar />
          </div>
          <div className="items-center flex gap-4 justify-self-end ml-auto sm:w-full">
            <div className="hidden md:block">
              <Breadcrumb title={title} previousPages={previousPages} />
            </div>
            <div className="ml-auto">
              <Search />
            </div>
            <NewItemsDropdown />
            <div className="hidden sm:block relative">
              <NotificationsSlideover />
            </div>
            <ProfileDropdown />
          </div>
        </div>
      </div>
    </div>
  )
}

const ProfileDropdown = () => {
  const history = useHistory()
  const auth = useAuth()

  const { given_name: givenName, family_name: familyName } = useUser()

  const handleLogout = () => {
    cookie.remove('sudo')
    auth.signoutRedirect()
  }

  return (
    <Dropdown
      avatar={<Avatar firstName={givenName} lastName={familyName} />}
      buttonHideLabel
      buttonVariant="ghost"
      popperOffset={[0, 4]}
    >
      <DropdownItem
        icon={UserIcon}
        label="My account"
        onClick={() => history.push('/my-account')}
      />
      <div className="sm:hidden">
        <DropdownItem
          icon={BellIcon}
          label="Notifications"
          onClick={() => history.push('/notifications')}
        />
      </div>
      <DropdownItem icon={LogoutIcon} label="Logout" onClick={handleLogout} />
    </Dropdown>
  )
}

const NewItemsDropdown = () => {
  const history = useHistory()
  const siteId = useCurrentSiteId()
  const [isCreatingNewClient, setCreatingNewClient] = useState(false)
  const [isCreatingNewVisit, setCreatingNewVisit] = useState(false)
  const { mutate: addOrder } = useAddOrder()
  const featureFlags = useActiveFeatureFlags()
  const can = useCurrentUserCan()
  const userCanCreateNewClient = can(CUSTOMERS_MANAGE)
  const userCanCreateNewOrder = can(RESERVATIONS_MANAGE)
  const { fireEvent } = useAnalytics()

  const handleNewOrder = (state = {}) => {
    addOrder(null, {
      onSuccess: (result) => {
        fireEvent('order_created', { via: 'global_header' })
        history.push(`/orders/${result.data.id}`, state)
      },
    })
  }

  const handleNewRetailOrder = () => {
    handleNewOrder({
      showAddModal: TYPE_PRODUCT,
    })
  }

  return (
    <>
      {userCanCreateNewClient && (
        <AddClientOverlay
          isOpen={isCreatingNewClient}
          onCancel={() => setCreatingNewClient(false)}
        />
      )}

      <AddVisitOverlay isOpen={isCreatingNewVisit} onCancel={() => setCreatingNewVisit(false)} />

      {(userCanCreateNewClient || userCanCreateNewOrder) && (
        <>
          <Dropdown
            buttonLabel="Create new"
            buttonVariant="secondary"
            buttonLeadingIcon={PlusIcon}
            buttonHideLabel
            buttonChevronHidden
          >
            {userCanCreateNewOrder && (
              <>
                <DropdownItem
                  icon={ShoppingBagIconSolid}
                  label="New order"
                  onClick={handleNewOrder}
                />
                {featureFlags.includes('add_product_wizard') && (
                  <DropdownItem
                    icon={ArchiveIconSolid}
                    label="New retail order"
                    onClick={handleNewRetailOrder}
                  />
                )}
              </>
            )}
            {userCanCreateNewClient && (
              <DropdownItem
                icon={UsersIcon}
                label="New client"
                onClick={() => setCreatingNewClient(true)}
              />
            )}
            <DropdownItem
              icon={MapIcon}
              label="New visit"
              onClick={() => setCreatingNewVisit(true)}
            />
          </Dropdown>
        </>
      )}
    </>
  )
}

const useCommands = () => {
  const currentSiteId = useCurrentSiteId()
  const organisationId = useOrganisationId()
  const notification = useNotificationContext()
  const { site } = useSiteConfig()
  const { brand_id: brandId } = site ?? {}

  const copyCommand = ({ value, label, successTitle, successDescription }) => ({
    key: `command-${label}`,
    name: `${label}`,
    category: 'Commands',
    icon: ClipboardCopyIcon,
    onClick: () => {
      navigator.clipboard.writeText(value)

      notification.notify(
        <Notification
          title={successTitle}
          description={successDescription}
          variant="success"
          autoDismiss
        />
      )
    },
  })

  return [
    copyCommand({
      value: currentSiteId,
      label: 'Copy site ID',
      successTitle: 'Site ID copied',
      successDescription: 'The site ID has been copied to your clipboard',
    }),
    copyCommand({
      value: organisationId,
      label: 'Copy organisation ID',
      successTitle: 'Organisation ID copied',
      successDescription: 'The organisation ID has been copied to your clipboard',
    }),
    copyCommand({
      value: brandId,
      label: 'Copy brand ID',
      successTitle: 'Brand ID copied',
      successDescription: 'The brand ID has been copied to your clipboard',
    }),
  ]
}

const Search = () => {
  const featureFlags = useActiveFeatureFlags()
  const [queryParam] = useQueryParam('query', withDefault(StringParam, ''))
  const [query, setQuery] = useState('')
  const [orderRef, setOrderRef] = useState('')
  const history = useHistory()
  const settingsRoutes = useSettingsRoutes()
  const settingsDirectoryRoute = SettingsDirectoryRoute()
  const reportsRoutes = useReportsRoutes()
  const reportsDirectoryRoute = ReportsDirectoryRoute()
  const practitionerAvailabilityRoutes = usePractitionerAvailabilityRoutes()
  const inventoryRoutes = useInventoryRoutes()
  const clientsRoutes = useClientsRoutes()
  const emailsRoutes = useEmailsRoutes()
  const emailsDirectoryRoute = EmailsDirectoryRoute()
  const calendarRoutes = useCalendarRoutes()
  const visitRoutes = useVisitRoutes()
  const ordersRoutes = useOrdersRoutes()
  const dashboardRoutes = useDashboardRoutes()
  const vouchersRoutes = useVouchersRoutes()
  const notification = useNotificationContext()
  const [open, setOpen] = useState(false)
  const checkPermissions = useCheckPermissions()
  const commands = useCommands()
  const internalUser = useIsInternalUser()
  const { fireEvent } = useAnalytics()

  useEffect(() => {
    setQuery(queryParam)
  }, [queryParam])

  const { isLoading: isLoadingOrder } = useGetOrderByRef(orderRef, {
    enabled: !!orderRef,
    onSuccess: (data) => {
      history.push(`/orders/${data.data.id}`)
      setOrderRef('')
    },
    onError(request) {
      notification.notify(
        <Notification
          title="Error"
          description={
            request.status === 404
              ? 'The order could not be found'
              : 'The order could not be loaded'
          }
          variant="danger"
          autoDismiss
        />
      )
      setQuery('')
      setOrderRef('')
    },
  })

  // This var will be used later if we use react-query to load results from the server
  const isLoading = false

  const isNavigating = isLoadingOrder

  const months = DateInfo.monthsFormat('short')
    .concat(DateInfo.monthsFormat('long'))
    .map((month) => month.toLowerCase())

  const filteredItems = useMemo(() => {
    const filteredItems = []

    if (!query) {
      return filteredItems
    }

    if (internalUser) {
      commands.forEach((command) => {
        if (!command.hide && command.name.toLowerCase().includes(query.toLowerCase())) {
          filteredItems.push({
            key: command.key,
            name: command.name,
            category: command.category,
            icon: command.icon,
            onClick: command.onClick,
          })
        }
      })
    }

    settingsRoutes.forEach((route) => {
      if (
        !route.hide &&
        (route.name.toLowerCase().includes(query.toLowerCase()) ||
          route.path.includes(query.toLowerCase())) &&
        checkPermissions(route.permissions)
      ) {
        filteredItems.push({
          key: route.path,
          path: route.path,
          name: route.name,
          category: 'Settings',
          icon: CogIcon,
        })
      }
    })

    settingsDirectoryRoute.forEach((route) => {
      if (route.name.toLowerCase() === query.toLowerCase() && checkPermissions(route.permissions)) {
        filteredItems.push({
          key: route.path,
          path: route.path,
          name: route.name,
          icon: CogIcon,
        })
      }
    })

    dashboardRoutes.forEach((route) => {
      if (
        checkPermissions(route.permissions) &&
        route.name.toLowerCase().includes(query.toLowerCase())
      ) {
        filteredItems.push({
          key: route.path,
          path: route.path,
          name: route.name,
          category: 'Dashboards',
          icon: PresentationChartLineIcon,
        })
      }
    })

    reportsRoutes.forEach((route) => {
      if (
        !route.hide &&
        checkPermissions(route.permissions) &&
        (route.name.toLowerCase().includes(query.toLowerCase()) ||
          route.path.includes(query.toLowerCase()))
      ) {
        filteredItems.push({
          key: route.path,
          path: route.path,
          name: route.name,
          category: 'Reports',
          icon: ChartBarIcon,
        })
      }
    })

    reportsDirectoryRoute.forEach((route) => {
      if (route.name.toLowerCase() === query.toLowerCase() && checkPermissions(route.permissions)) {
        filteredItems.push({
          key: route.path,
          path: route.path,
          name: route.name,
          icon: ChartBarIcon,
        })
      }
    })

    inventoryRoutes.forEach((route) => {
      if (
        (route.name.toLowerCase().includes(query.toLowerCase()) ||
          route.path.includes(query.toLowerCase())) &&
        checkPermissions(route.permissions)
      ) {
        filteredItems.push({
          key: route.path,
          path: route.path,
          name: route.name,
          category: 'Inventory',
          icon: CubeIcon,
        })
      }
    })

    emailsRoutes.forEach((route) => {
      if (
        route.name.toLowerCase().includes(query.toLowerCase()) ||
        route.path.includes(query.toLowerCase())
      ) {
        filteredItems.push({
          key: route.path,
          path: route.path,
          name: route.name,
          category: 'Emails',
          icon: MailIcon,
        })
      }
    })

    emailsDirectoryRoute.forEach((route) => {
      if (route.name.toLowerCase() === query.toLowerCase()) {
        filteredItems.push({
          key: route.path,
          path: route.path,
          name: route.name,
          icon: MailIcon,
        })
      }
    })

    calendarRoutes.forEach((route) => {
      if (route.name.toLowerCase().includes(query.toLowerCase())) {
        filteredItems.push({
          key: route.path,
          path: route.path,
          name: route.name,
          icon: CalendarIcon,
        })
      }
    })

    ordersRoutes.forEach((route) => {
      if (
        route.name.toLowerCase().includes(query.toLowerCase()) &&
        checkPermissions(route.permissions)
      ) {
        filteredItems.push({
          key: route.path,
          path: route.path,
          name: route.name,
          icon: ShoppingBagIcon,
        })
      }
    })

    practitionerAvailabilityRoutes.forEach((route) => {
      if (
        route.name.toLowerCase().includes(query.toLowerCase()) &&
        checkPermissions(route.permissions)
      ) {
        filteredItems.push({
          key: route.path,
          path: route.path,
          name: route.name,
          icon: BriefcaseIcon,
        })
      }
    })

    clientsRoutes.forEach((route) => {
      if (
        route.name.toLowerCase().includes(query.toLowerCase()) &&
        checkPermissions(route.permissions)
      ) {
        filteredItems.push({
          key: route.path,
          path: route.path,
          name: route.name,
          icon: UsersIcon,
        })
      }
    })

    vouchersRoutes.forEach((route) => {
      if (
        route.name.toLowerCase().includes(query.toLowerCase()) &&
        checkPermissions(route.permissions)
      ) {
        filteredItems.push({
          key: route.path,
          path: route.path,
          name: route.name,
          icon: TicketIcon,
        })
      }
    })

    visitRoutes.forEach((route) => {
      if (route.name.toLowerCase().includes(query.toLowerCase())) {
        filteredItems.push({
          key: route.path,
          path: route.path,
          name: route.name,
          icon: MapIcon,
        })
      }
    })

    if (query.match(/^[0-9A-Z]{5,6}$/i) !== null) {
      filteredItems.push({
        key: query,
        type: 'order',
        orderRef: query.toUpperCase(),
        name: `Go to order ${query.toUpperCase()}`,
        category: 'Orders',
        icon: ShoppingBagIcon,
      })
    }

    const queryParts = query.split(/\s/)

    if (
      queryParts.length === 2 &&
      Number.isInteger(parseInt(queryParts[0])) &&
      months.includes(queryParts[1].toLowerCase())
    ) {
      const currentYear = DateTime.local().year
      const monthIndex = months.indexOf(queryParts[1].toLowerCase())
      const monthNum = monthIndex >= 12 ? monthIndex - 11 : monthIndex + 1
      const currentMonthNum = DateTime.local().month
      const dayNum = parseInt(queryParts[0])

      const goToYear = monthNum >= currentMonthNum ? currentYear : currentYear + 1
      const goToMonth = monthNum < 10 ? `0${monthNum}` : monthNum
      const goToDay = dayNum < 10 ? `0${dayNum}` : dayNum
      const dateString = `${goToYear}-${goToMonth}-${goToDay}`

      filteredItems.push({
        key: 'date',
        path: `/calendar?date=${dateString}`,
        name: `Go to ${queryParts[0]} ${sentenceCase(queryParts[1])}`,
        category: 'Calendar',
        icon: CalendarIcon,
      })
    }

    if (query.match(/^how\s/i)) {
      filteredItems.push({
        key: 'guides',
        url: `https://help.try.be/kb/en/search/${encodeURIComponent(query)}`,
        name: `Search guides for '${query}'`,
        icon: QuestionMarkCircleIcon,
      })
    }

    filteredItems.push({
      key: 'order-search',
      path: `/orders?query=${encodeURIComponent(query)}`,
      name: `Search orders for '${query}'`,
      icon: ShoppingBagIcon,
    })

    filteredItems.push({
      key: 'client-search',
      path: `/clients?query=${encodeURIComponent(query)}`,
      name: `Search clients for '${query}'`,
      icon: UsersIcon,
    })

    return filteredItems
  }, [
    query,
    months,
    settingsRoutes,
    dashboardRoutes,
    reportsRoutes,
    inventoryRoutes,
    emailsRoutes,
    calendarRoutes,
    ordersRoutes,
    practitionerAvailabilityRoutes,
    clientsRoutes,
  ])

  const handleSelect = (item) => {
    fireEvent('global_search_completed', {
      search_query: query,
      item_name: item.name,
    })

    if (item.path) {
      history.push(item.path)
    } else if (item.url) {
      window.open(item.url, '_blank')
    } else if (item.type === 'order') {
      setOrderRef(item.orderRef)
    } else if (item.onClick) {
      item.onClick()
    }
    setOpen(false)
  }

  // `metaKey` is the Cmd key on MacOS.
  // We're registering a listener on both Ctrl-K and Cmd-K
  // to avoid having to conditionally register a listener
  // depending on the user's OS.
  const handleKeyDown = useCallback((event) => {
    if ((event.ctrlKey || event.metaKey) && (event.key === 'k' || event.key === 'K')) {
      event.preventDefault()
      openGlobalSearch({ via: 'key_command' })
    }
  }, [])

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [handleKeyDown])

  const platform = navigator['userAgentData']?.platform || navigator?.platform || 'unknown'
  const isMac = platform.toLowerCase().includes('mac')
  const modifierKey = isMac ? '⌘' : 'Ctrl'

  const openGlobalSearch = (opts?: { via?: string }) => {
    setOpen(true)

    fireEvent('global_search_opened', {
      via: opts?.via,
    })
  }

  const dismiss = () => {
    setOpen(false)

    fireEvent('global_search_dismissed', {
      search_query: query,
    })
  }

  return (
    <>
      <div className="md:hidden">
        <GlobalHeaderButton
          label="Search Trybe"
          hideLabel
          onClick={() => openGlobalSearch({ via: 'click' })}
          leadingIcon={SearchIcon}
        />
      </div>
      <div className="hidden md:block">
        <GlobalHeaderButton
          label="Search Trybe"
          onClick={() => openGlobalSearch({ via: 'click' })}
          leadingIcon={SearchIcon}
          className="border-gray-100 border hover:border-gray-300 !rounded-lg px-3 py-2 min-w-[20rem]"
          trailingIcon={
            <div className="flex h-full space-x-1">
              <KeyCommand>{`${modifierKey} K`}</KeyCommand>
            </div>
          }
        />
      </div>

      <Transition.Root show={open} as={Fragment} afterLeave={() => setQuery('')} appear>
        <Dialog as="div" className="relative z-50" onClose={() => dismiss()}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20 backdrop-filter backdrop-blur">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="mt-[150px] p-2 mx-auto max-w-xl transform divide-y divide-gray-100 rounded-xl bg-background shadow-2xl ring-1 ring-black ring-opacity-5 transition-all md:mt-[80px]">
                <Combobox as="div" value={query} onChange={handleSelect}>
                  <div className="relative">
                    <label htmlFor="search" className="sr-only">
                      Search
                    </label>
                    <div className="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center">
                      {!isLoading && (
                        <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                      )}
                      {isLoading && <LoadingSpinner size={SpinnerSize.sm} />}
                    </div>
                    <Combobox.Input
                      id="search"
                      name="search"
                      className="block w-full bg-background border border-gray-300 rounded-md py-2 pl-10 pr-3 text-sm placeholder-gray-500 focus:outline-none focus:text-gray-900 focus:placeholder-gray-400 focus:ring-2 focus:ring-violet focus:border-gray-300 focus:ring-offset-2 sm:text-sm"
                      placeholder="Search Trybe"
                      type="search"
                      autoComplete="off"
                      onChange={(event) => setQuery(event.target.value)}
                    />
                    {isNavigating && (
                      <div className="pointer-events-none absolute inset-y-0 right-0 pr-3 flex items-center">
                        <LoadingSpinner size={SpinnerSize.sm} variant="info" />
                      </div>
                    )}
                    {filteredItems.length > 0 && (
                      <Combobox.Options className="absolute z-30 mt-2 max-h-60 w-full overflow-auto rounded-md bg-background p-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        {filteredItems.map((item) => (
                          <Combobox.Option
                            key={item.key}
                            value={item}
                            className={({ active }) =>
                              classNames(
                                'flex items-center cursor-pointer select-none p-2 pl-3 pr-9 rounded-lg',
                                active && 'bg-violet text-white'
                              )
                            }
                          >
                            {({ active, selected }) => (
                              <>
                                {item.icon && (
                                  <item.icon
                                    className={`${
                                      !active && !selected ? 'text-gray-500' : 'text-white'
                                    } h-5 w-5 mr-2`}
                                    aria-hidden="true"
                                  />
                                )}
                                {item.category && (
                                  <>
                                    {item.category} <ChevronRightIcon className="w-4 h-4 mx-1" />
                                  </>
                                )}
                                {item.name}
                              </>
                            )}
                          </Combobox.Option>
                        ))}
                      </Combobox.Options>
                    )}
                  </div>
                  {query === '' && (
                    <div className="py-14 px-6 text-center sm:px-14">
                      <SearchIcon className="mx-auto h-6 w-6 text-gray-400" aria-hidden="true" />
                      <p className="mt-4 text-sm text-gray-700">
                        Looking for a page in the app? Type it in here to search!
                      </p>
                      <p className="md:hidden mt-2 text-sm text-gray-700">
                        Click anywhere on the page to exit.
                      </p>
                      <p className="hidden md:block mt-2 text-sm text-gray-700">
                        Click anywhere on the page or press <KeyCommand>Esc</KeyCommand> to exit.
                      </p>
                      <p className="hidden md:block mt-2 text-sm text-gray-700">
                        Want to open search back up? Press{' '}
                        <KeyCommand>{`${modifierKey} K`}</KeyCommand>.{' '}
                      </p>
                    </div>
                  )}
                </Combobox>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  )
}
