import { createPortal } from 'react-dom'
import { useSidebar } from './contexts'
import { ChevronDoubleLeftIcon, ChevronDoubleRightIcon } from '@heroicons/react/solid'

export const CollapseButton = () => {
  const { minimised, setMinimised, lightTheme } = useSidebar()

  return createPortal(
    <button
      title={minimised ? 'Expand sidebar' : 'Collapse sidebar'}
      className={`hidden md:block ${
        minimised ? 'translate-x-[48px]' : 'translate-x-[185px]'
      } z-40 transition-transform border ${
        lightTheme
          ? 'bg-background border-border dark:text-white'
          : 'bg-[#5e345c] text-white border-transparent'
      } rounded shadow-sm p-1 m-3 absolute top-0 left-0`}
      onClick={() => setMinimised(!minimised)}
    >
      {minimised && <ChevronDoubleRightIcon className="w-5 h-5" />}

      {!minimised && <ChevronDoubleLeftIcon className="w-5 h-5" />}
    </button>,
    document.body
  )
}
