import { lazy } from 'react'
import { matchPath, Link as RouterLink, NavLink as RouterNavLink } from 'react-router-dom'
import { routes as appRoutes } from 'routes'
import { Link } from '@trybeapp/ui.link'
import { Table } from '@trybeapp/ui.table'
import classNames from 'classnames'

export const PreloadRouteComponent = (path, routes) => {
  const component = findComponentForRoute(path, routes ?? appRoutes)
  if (component && component.preload) {
    component.preload()
  }
}

export const findComponentForRoute = (path, routes) => {
  const matchingRoute = routes.find((route) =>
    matchPath(path, {
      path: route.path,
      exact: route.exact,
    })
  )
  return matchingRoute ? matchingRoute.component : null
}

export const ReactLazyPreload = (importStatement) => {
  const Component = lazy(() => componentLoader(importStatement))
  Component.preload = importStatement
  return Component
}

export const componentLoader = (lazyComponent, attemptsLeft = 3) => {
  return new Promise((resolve, reject) => {
    lazyComponent()
      .then(resolve)
      .catch((error) => {
        // let us retry after 1500 ms
        setTimeout(() => {
          if (attemptsLeft === 1) {
            reject(error)
            return
          }
          componentLoader(lazyComponent, attemptsLeft - 1).then(resolve, reject)
        }, 1500)
      })
  })
}

export const LinkWithPreload = ({ to, onPreload = null, ...rest }) => {
  return (
    <RouterLink to={to} onMouseEnter={() => PreloadRouteComponent(to, rest.routes)} {...rest} />
  )
}

export const UnstyledNavLinkWithPreload = ({ to, onPreload = null, ...rest }) => (
  <RouterNavLink to={to} onMouseEnter={() => PreloadRouteComponent(to, rest.routes)} {...rest} />
)

export const TailwindLinkWithPreload = ({ to, onPreload = null, ...rest }) => (
  <RouterNavLink
    to={to}
    onMouseEnter={() => PreloadRouteComponent(to, rest.routes)}
    className={classNames('font-medium text-violet hover:underline', rest.className)}
    {...rest}
  />
)

export const NavLinkWithPreload = ({ to, onPreload = null, minimal = false, ...rest }) => {
  return (
    <Link
      as={RouterNavLink}
      variant="secondary"
      to={to}
      onMouseEnter={() => PreloadRouteComponent(to, rest.routes)}
      {...rest}
    />
  )
}

export const TdLinkWithPreload = ({ to, children, ...rest }) => {
  return (
    <Table.Td {...rest}>
      <TailwindLinkWithPreload to={to} className="hover:no-underline">
        {children}
      </TailwindLinkWithPreload>
    </Table.Td>
  )
}
