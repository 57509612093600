import { FireableEvent } from 'constants/analyticEvents'
import { useCurrentSite } from 'contexts/SiteConfig'
import { useOrganisationConfig } from 'hooks/UseOrganisationConfig'
import { useEffect } from 'react'

type EventOptions = { [key: string]: string | boolean | number }

export const fireEvent = (eventName: FireableEvent, eventOptions: EventOptions = {}) => {
  if (window.gtag) {
    window.gtag('event', eventName, {
      ...eventOptions,
    })
  }
}

export const updateUserProperties = (properties: EventOptions) => {
  if (window.gtag) {
    window.gtag('set', 'user_properties', {
      ...properties,
    })
  }
}

export const useHydrateUserProperties = () => {
  const organisationId = useOrganisationConfig()
  const prefersDarkScheme =
    window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches

  useEffect(() => {
    if (organisationId) {
      updateUserProperties({
        organisation_id: organisationId,
        prefers_dark_scheme: prefersDarkScheme,
      })
    }
  }, [organisationId])
}

export const useAnalytics = () => {
  const site = useCurrentSite()

  return {
    fireEvent: (eventName: FireableEvent, eventOptions: EventOptions = {}) => {
      fireEvent(eventName, {
        site_id: site.id,
        site_name: site.name,
        ...eventOptions,
      })
    },
  }
}

export const Analytics = () => {
  useHydrateUserProperties()

  return null
}
