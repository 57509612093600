import {
  useQuery,
  useMutation,
  useQueryClient,
  useInfiniteQuery,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query'
import { UsersApi } from '@trybeapp/sdk.auth'
import { useCurrentSiteId } from 'contexts/SiteConfig'
import { usePageQueryParam } from 'hooks/UsePageQueryParam'
import { ListAllUsersRequest, ListUsers1, UsersApi as TsUsersApi } from '@trybeapp/sdk'
import { apiConfiguration } from 'utilities/TrybeApiClient'

const api = new UsersApi()
const tsApi = new TsUsersApi(apiConfiguration)

export const useUsers = (
  params: ListAllUsersRequest = {},
  options?: UseQueryOptions<ListUsers1>
) => {
  const siteId = useCurrentSiteId()
  params.perPage = params.perPage ?? 100

  return useQuery(
    ['users', siteId, params],
    async () =>
      await tsApi.listAllUsers({
        ...params,
        perPage: params.perPage ?? 100,
        siteId: siteId,
      }),
    options
  )
}

export const useInfiniteUsers = (params: any = {}) => {
  const siteId = useCurrentSiteId()

  return useInfiniteQuery(
    ['infiniteUsers'],
    async ({ pageParam = 1 }) => {
      const response = await tsApi.listAllUsers({
        ...params,
        page: pageParam,
        siteId,
      })
      return {
        data: response.data,
      }
    },
    {
      getNextPageParam: (lastPage: any) => lastPage.nextPage,
    }
  )
}

export const useGetUser = (userId, options = {}) => {
  return useQuery(['users', userId], async () => await api.userShow(userId), options)
}

export const usePagedUsers = (params = {}) => {
  const [page] = usePageQueryParam()

  return useUsers({ page, perPage: 0, ...params })
}

export const useUser = (userId, params = {}) =>
  useQuery(['users', userId, params], async () => await api.userShow(userId, params))

export const useAddUser = () => {
  const queryClient = useQueryClient()

  return useMutation(async (user) => await api.userStore(user), {
    onSuccess: () => {
      queryClient.invalidateQueries('users')
    },
  })
}

export const useUpdateUser = () => {
  const queryClient = useQueryClient()

  return useMutation(
    async (args: [string, any]) => {
      const [userId, user] = args
      return await api.userUpdate(userId, user)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('users')
      },
    }
  )
}

export const useDeleteUser = () => {
  const queryClient = useQueryClient()

  return useMutation(async (userId) => await api.userDestroy(userId), {
    onSuccess: () => {
      queryClient.invalidateQueries('users')
    },
  })
}

export const useResetUserPassword = () => {
  return useMutation(async (userId: string) => await api.userPasswordReset(userId))
}

export const useDisableUser2fa = () => {
  const queryClient = useQueryClient()
  const siteId = useCurrentSiteId()

  return useMutation(
    async (userId: string) => await api.userTwoFactorAuthenticationDisable(userId, siteId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('users')
      },
    }
  )
}

export const useGetUserSites = (
  userId: string,
  options: UseQueryOptions = {}
): UseQueryResult<any> => {
  return useQuery(['userSites', userId], async () => await tsApi.getUserSites({ userId }), options)
}
