import { SearchIcon } from '@heroicons/react/outline'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import { ArrowDownIcon, ArrowUpIcon, SelectorIcon } from '@heroicons/react/solid'

export const Table = ({ allowOverflow = false, ghost = false, divide = true, children }) => {
  const overflowClasses = allowOverflow ? '' : 'overflow-x-auto'
  const tableContainerClasses = classNames({
    'shadow-sm border border-border sm:rounded-lg': !ghost,
    'overflow-hidden': !allowOverflow,
    'divide-gray-200': divide,
  })
  const tableClasses = classNames({
    'min-w-full': true,
    'divide divide-border': true,
  })

  return (
    <div className="flex flex-col">
      <div className={overflowClasses}>
        <div className="align-middle inline-block min-w-full">
          <div className={tableContainerClasses}>
            <table className={tableClasses}>{children}</table>
          </div>
        </div>
      </div>
    </div>
  )
}

Table.THead = ({ children, ghost = false }) => (
  <thead className={`${!ghost ? 'bg-background-muted' : ''}`}>{children}</thead>
)

Table.TFoot = ({ children }) => <tfoot>{children}</tfoot>

Table.Th = ({
  colSpan = 1,
  children = null,
  condensed = false,
  padding = 'lg',
  textSize = 'sm',
  className = '',
  shrink = false,
  monetary = false,
  sortOrder = undefined,
  onSort = undefined,
  noWrap = false,
  ...rest
}) => {
  if (condensed) {
    padding = 'md'
  }

  const classes = {
    'whitespace-nowrap w-0': shrink,
    'px-6 py-3': padding === 'lg',
    'px-3 py-3': padding === 'md',
    'px-2 py-2': padding === 'sm',
    'px-1 py-1': padding === 'xs',
    'text-right': monetary,
    'text-sm': textSize === 'sm',
    'text-base': textSize === 'md' || textSize === 'base',
    'text-lg': textSize === 'lg',
    'cursor-pointer': onSort !== undefined,
    'text-left font-medium text-gray-500 dark:text-gray-400 tracking-wide break-normal group': true,
  }

  if (className) {
    classes[className] = true
  }

  const handleClick = () => {
    if (typeof onSort === 'function') {
      onSort()
    }
  }

  return (
    <th
      scope="col"
      colSpan={colSpan}
      className={classNames(classes)}
      onClick={handleClick}
      {...rest}
    >
      <div className="flex w-full items-center">
        <div className={`flex-1 ${noWrap ? 'text-nowrap' : ''}`}>{children}</div>
        <div className="sm:ml-1">
          {!sortOrder && onSort && (
            <SelectorIcon className="w-4 h-4 text-gray-300 group-hover:text-gray-400" />
          )}
          {sortOrder === 'asc' && <ArrowUpIcon className="w-4 h-4 text-violet-400" />}
          {sortOrder === 'desc' && <ArrowDownIcon className="w-4 h-4 text-violet-400" />}
        </div>
      </div>
    </th>
  )
}

Table.TBody = ({ divide = true, className = '', children }) => {
  const classes = classNames(className, {
    'bg-background': true,
    'divide-y divide-border': divide,
  })

  return <tbody className={classes}>{children}</tbody>
}

Table.Tr = ({ children, className = '', onClick = null }) => (
  <tr className={`${className}`} onClick={onClick}>
    {children}
  </tr>
)

Table.Td = ({
  children = undefined,
  colSpan = 1,
  shrink = false,
  condensed = false,
  padding = 'lg',
  textSize = 'sm',
  className = '',
  monetary = false,
  link = undefined,
}) => {
  if (condensed) {
    padding = 'md'
  }

  const cellClasses = {
    'whitespace-nowrap w-0': shrink,
  }

  if (className) {
    cellClasses[className] = true
  }

  const containerClasses = {
    'break-normal': true,
    'group block whitespace-nowrap': !!link,
    'px-6 py-4': padding === 'lg',
    'px-3 py-3': padding === 'md',
    'px-2 py-2': padding === 'sm',
    'px-1 py-1': padding === 'xs',
    'text-sm': textSize === 'sm',
    'text-base': textSize === 'md' || textSize === 'base',
    'text-lg': textSize === 'lg',
    'text-right font-mono': monetary,
  }

  return (
    <td colSpan={colSpan} className={classNames(cellClasses)}>
      {link && (
        <Link to={link} className={classNames(containerClasses)}>
          {children}
        </Link>
      )}
      {!link && <div className={classNames(containerClasses)}>{children}</div>}
    </td>
  )
}

Table.TdLink = ({ to, ...props }) => (
  <Table.Td {...props}>
    <Link to={to} className={`group block whitespace-nowrap text-sm`}>
      {props.children}
    </Link>
  </Table.Td>
)

Table.TrNoData = ({
  colSpan,
  className = '',
  condensed = false,
  title = 'Nothing found',
  text = "We didn't find any results matching your query",
  showIcon = true,
  textAlign = 'center',
}) => {
  const textAlignClass = textAlign === 'left' ? 'text-left' : 'text-center'

  return (
    <Table.Tr className={className}>
      <Table.Td colSpan={1} className={`md:hidden ${textAlignClass}`}>
        {showIcon && (
          <div className={`mx-auto ${textAlignClass}`}>
            <SearchIcon className="w-12 h-12 mx-auto" />
          </div>
        )}
        {title && <div className="text-xl font-medium my-2">{title}</div>}
        <p>{text}</p>
      </Table.Td>
      <Table.Td colSpan={colSpan} condensed={condensed} className="hidden md:table-cell">
        <div className={textAlignClass}>
          {showIcon && (
            <div className={`mx-auto ${textAlignClass}`}>
              <SearchIcon className="w-12 h-12 mx-auto" />
            </div>
          )}
          {title && <div className="text-xl font-medium my-2">{title}</div>}
          <p>{text}</p>
        </div>
      </Table.Td>
    </Table.Tr>
  )
}

Table.TrLoading = ({ className = '', cols, condensed = false }) => (
  <Table.Tr className={className}>
    <Table.Td colSpan={1} className="md:hidden text-center">
      <div className="w-24 h-4 bg-background-muted animate-pulse" />
    </Table.Td>
    {[...Array(cols)].map((_, i) => (
      <Table.Td condensed={condensed} key={`loading_${i}`} className="hidden md:table-cell">
        <div className="w-24 h-4 bg-background-muted animate-pulse" />
      </Table.Td>
    ))}
  </Table.Tr>
)

Table.ThLoading = ({ className = '', cols, condensed = false }) => (
  <Table.Tr className={className}>
    <Table.Th colSpan={1} className="md:hidden text-center">
      <div className="w-24 h-4 bg-gray-200 animate-pulse" />
    </Table.Th>
    {[...Array(cols)].map((_, i) => (
      <Table.Th condensed={condensed} key={`loading_${i}`} className="hidden md:table-cell">
        <div className="w-24 h-4 bg-gray-200 animate-pulse" />
      </Table.Th>
    ))}
  </Table.Tr>
)

Table.Loading = ({ cols, rows = 5, condensed = false }) => (
  <Table>
    <Table.THead>
      <Table.ThLoading cols={cols} condensed={condensed} />
    </Table.THead>
    <Table.TBody>
      <Table.LoadingRows rows={rows} cols={cols} />
    </Table.TBody>
  </Table>
)

Table.LoadingRows = ({ rows, cols, ...props }) => (
  <>
    {[...Array(rows)].map((e, i) => (
      <Table.TrLoading key={i} cols={cols} {...props} />
    ))}
  </>
)

Table.ErrorRow = ({ colSpan, message = undefined }) => {
  message = message || 'The data was unable to be fetched, please reload and try again'

  return (
    <>
      <Table.Td colSpan={1} className="md:hidden text-center">
        {message}
      </Table.Td>
      <Table.Td colSpan={colSpan} className="hidden md:table-cell text-center">
        {message}
      </Table.Td>
    </>
  )
}
