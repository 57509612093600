interface KeyCommandProps {
  className?: string
  children: string
}

export const KeyCommand: React.FC<KeyCommandProps> = (props) => (
  <span
    className={`${props.className} inline-block h-[21px] font-medium leading-relaxed px-1 text-xs bg-background-muted text-gray-500 rounded border border-border`}
  >
    {props.children}
  </span>
)
