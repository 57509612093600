import { useUpdateCustomer } from 'api/Customers'
import { useUploadMedia } from 'api/Media'
import { useCallback, useRef, useState } from 'react'
import Webcam from 'react-webcam'
import { Button } from 'ui/components/Button'
import { Modal } from 'ui/Modal'
import { useParams } from 'react-router-dom'
import { Notification, useNotificationContext } from 'ui/components/Notification'
import { SupportIcon } from '@heroicons/react/solid'

export const TakePhotoModal = ({ isOpen, onClose }) => {
  const { clientId } = useParams()
  const webcamRef = useRef(null)
  const [uploading, setUploading] = useState(false)
  const [pendingPhoto, setPendingPhoto] = useState(null)
  const { mutateAsync: uploadMedia } = useUploadMedia()
  const { mutateAsync: updateClient } = useUpdateCustomer()
  const handleImageUpload = async (file) => (await uploadMedia(file)).data
  const notification = useNotificationContext()

  const notifySuccess = () => {
    notification.notify(
      <Notification
        title="Avatar uploaded"
        description={`The profile picture for this client has successfully been saved.`}
        variant="success"
        autoDismiss
      />
    )
  }

  const capture = useCallback(async () => {
    const imageSrc = webcamRef.current.getScreenshot()

    try {
      const file = await fetch(imageSrc)
        .then(function (res) {
          return res.arrayBuffer()
        })
        .then(function (buf) {
          return new File([buf], 'image.jpg', { type: 'image/jpg' })
        })

      setPendingPhoto({
        src: imageSrc,
        file,
      })
    } catch (_) {
      // Do nothing
    }
  }, [webcamRef])

  const upload = async () => {
    setUploading(true)

    try {
      const uploadedMedia = await handleImageUpload(pendingPhoto.file)

      // Now update the client with the ID.
      await updateClient([
        clientId,
        {
          avatar_id: uploadedMedia.id,
        },
      ])

      onClose()
      notifySuccess()
    } catch (_) {
      // Do nothing
    } finally {
      setUploading(false)
    }
  }

  const tryAgain = () => {
    setPendingPhoto(null)
  }

  const handleClose = () => {
    setPendingPhoto(null)
    onClose()
  }

  enum PermissionStatuses {
    Unspecified = 0,
    Granted = 1,
    Denied = 2,
  }

  const [permissionStatus, setPermissionStatus] = useState(PermissionStatuses.Unspecified)

  return (
    <Modal isOpen={isOpen} onClose={handleClose} showHideIcon={false} title="">
      <div className="space-y-4">
        <div className={`-m-6 -my-8 mb-6 ${pendingPhoto ? 'opacity-0 absolute' : ''}`}>
          <div
            className={`aspect-square rounded-lg rounded-b-none flex flex-col items-center justify-center overflow-hidden ${
              permissionStatus !== PermissionStatuses.Granted ? 'bg-gray-600' : ''
            }`}
          >
            <Webcam
              audio={false}
              screenshotFormat="image/jpeg"
              className={`${
                permissionStatus !== PermissionStatuses.Granted ? 'hidden' : ''
              } aspect-square`}
              ref={webcamRef}
              videoConstraints={{
                width: 512,
                height: 512,
              }}
              onUserMediaError={() => setPermissionStatus(PermissionStatuses.Denied)}
              onUserMedia={() => setPermissionStatus(PermissionStatuses.Granted)}
            />
            {permissionStatus === PermissionStatuses.Unspecified && (
              <div className="text-white font-medium text-2xl">
                <div>Waiting for camera...</div>
                <p className="mt-2 text-sm font-normal">
                  When prompted, please "Allow" access to the camera
                </p>
                <button className="mt-2 text-sm font-medium flex space-x-1 hover:underline cursor-pointer">
                  <SupportIcon aria-hidden="true" className="w-5 h-5" />
                  <span>Get help</span>
                </button>
              </div>
            )}

            {permissionStatus === PermissionStatuses.Denied && (
              <div className="text-white font-medium text-2xl">
                <div>Can't access the camera</div>
                <p className="mt-2 text-sm font-normal">
                  Please ensure your browser isn't blocking access to the camera.
                </p>
                <button className="mt-2 text-sm font-medium flex space-x-1 hover:underline cursor-pointer">
                  <SupportIcon aria-hidden="true" className="w-5 h-5" />
                  <span>Get help</span>
                </button>
              </div>
            )}
          </div>
        </div>

        {pendingPhoto && (
          <>
            <img src={pendingPhoto.src} className="rounded-lg" alt="Pending" />
            <div className="mt-2 text-sm text-gray-500 text-center">How's this?</div>
          </>
        )}

        <div className="space-y-2">
          {!pendingPhoto && (
            <Button
              label={'Take photo'}
              onClick={capture}
              disabled={permissionStatus !== PermissionStatuses.Granted}
              variant="primary"
              fullWidth
            />
          )}

          {pendingPhoto && (
            <>
              <Button
                label="Use photo"
                onClick={upload}
                loading={uploading}
                variant="primary"
                fullWidth
              />
              <Button label="Try again" onClick={tryAgain} variant="secondary" fullWidth />
            </>
          )}

          <Button label="Cancel" onClick={handleClose} fullWidth />
        </div>
      </div>
    </Modal>
  )
}
