import {
  ArchiveIcon,
  CurrencyPoundIcon,
  IdentificationIcon,
  OfficeBuildingIcon,
  AdjustmentsIcon,
  ShoppingBagIcon,
  UserGroupIcon,
  ChartPieIcon,
} from '@heroicons/react/solid'
import { useMemo } from 'react'
import { FeatureCard } from 'ui/components/FeatureCard'
import { ScreenLayout } from 'ui/components/ScreenLayout'
import { useRoutes } from './Reports'
import { useCurrentUserCan } from 'hooks/UseCurrentUserCan'
import { ORG_REPORTS_VIEW } from 'constants/permissions'
import { FeatureCardGrid } from 'ui/components/FeatureCardGrid'

export const Home = () => {
  const routes = useRoutes()
  const can = useCurrentUserCan()
  const userCanViewOrgReports = can(ORG_REPORTS_VIEW)

  const categories = [
    {
      key: 'sales',
      title: 'Sales',
      subtitle: 'Revenues and sold items',
      icon: ShoppingBagIcon,
      iconBackground: 'bg-purple-50 dark:bg-purple-500/25',
      iconForeground: 'text-purple-700 dark:text-purple-400',
    },
    {
      key: 'customers',
      title: 'Customers',
      subtitle: 'Activity of your customers',
      icon: UserGroupIcon,
      iconBackground: 'bg-orange-50 dark:bg-orange-500/25',
      iconForeground: 'text-orange-700 dark:text-orange-400',
    },
    {
      key: 'occupancy',
      title: 'Occupancy',
      subtitle: 'Utilisation at your site',
      icon: ChartPieIcon,
      iconBackground: 'bg-red-50 dark:bg-red-500/25',
      iconForeground: 'text-red-700 dark:text-red-400',
    },
    {
      key: 'finance',
      title: 'Finance',
      subtitle: 'Payments and balances',
      icon: CurrencyPoundIcon,
      iconBackground: 'bg-green-50 dark:bg-green-500/25',
      iconForeground: 'text-green-700 dark:text-green-400',
    },
    {
      key: 'inventory',
      title: 'Inventory',
      subtitle: 'Product stock levels',
      icon: ArchiveIcon,
      iconBackground: 'bg-pink-50 dark:bg-pink-500/25',
      iconForeground: 'text-pink-700 dark:text-pink-400',
    },
    {
      key: 'membership',
      title: 'Memberships',
      subtitle: 'Insights into your memberships',
      icon: IdentificationIcon,
      iconBackground: 'bg-fuchsia-50 dark:bg-fuchsia-500/25',
      iconForeground: 'text-fuchsia-700 dark:text-fuchsia-400',
    },
    {
      key: 'operations',
      title: 'Operations',
      subtitle: 'Information to support your operations',
      icon: AdjustmentsIcon,
      iconBackground: 'bg-indigo-50 dark:bg-indigo-500/25',
      iconForeground: 'text-indigo-700 dark:text-indigo-400',
    },
    userCanViewOrgReports && {
      key: 'organisation',
      title: 'Organisation',
      subtitle: 'Reports across all sites in your organisation',
      icon: OfficeBuildingIcon,
      iconBackground: 'bg-yellow-50 dark:bg-yellow-500/25',
      iconForeground: 'text-yellow-700 dark:text-yellow-400',
    },
  ].filter(Boolean)

  const linksByCategory = useMemo(() => {
    return routes.reduce((linksByCategory, route) => {
      if (route.hide) {
        return linksByCategory
      }

      if (
        Array.isArray(route.permissions) &&
        route.permissions.find((permission) => can(permission)) === undefined
      ) {
        return linksByCategory
      }

      if (!linksByCategory[route.category]) {
        linksByCategory[route.category] = []
      }

      linksByCategory[route.category].push({
        label: route.name,
        to: route.path,
      })

      return linksByCategory
    }, {})
  }, [routes])

  return (
    <ScreenLayout title="Reports">
      <FeatureCardGrid>
        {categories.map((category) => (
          <FeatureCard
            key={category.key}
            title={category.title}
            subtitle={category.subtitle}
            icon={category.icon}
            links={linksByCategory[category.key]}
            iconBackground={category.iconBackground}
            iconForeground={category.iconForeground}
          />
        ))}
      </FeatureCardGrid>
    </ScreenLayout>
  )
}
