import { useUpdateCustomer } from 'api/Customers'
import { Modal, ModalButtons } from 'ui/Modal'
import { Button } from 'ui/components/Button'
import { useState } from 'react'
import { Field, FieldProps, Form, Formik } from 'formik'
import { Input } from 'ui/components/Form/Input'
import { ConnectedField as BaseConnectedField } from 'components/ConnectedField'
import { useNotificationContext, Notification } from 'ui/components/Notification'
import { ErrorAlert } from 'components/ErrorAlert'
import { ErrorProvider } from 'contexts/Error'
import { PencilIcon } from '@heroicons/react/solid'
import { DateOfBirth } from 'ui/components/Form/DateOfBirthInput'
import { DateTime } from 'luxon'
import { object, string } from 'yup'
import { Label } from 'ui/components/Form/Label'
import { ErrorMessage } from 'ui/components/Form/ErrorMessage'
import { default as BasePhoneInput } from 'react-phone-number-input'
import { useSiteConfig } from 'hooks/UseSiteConfig'
import { LocaleSelect } from 'ui/components/LocaleSelect'

const ConnectedField = BaseConnectedField as any

export const EditDetailsModal = ({ client }) => {
  const [show, setShow] = useState(false)
  const notification = useNotificationContext()
  const { site: { country_code: countryCode = '' } = {} } = useSiteConfig()

  const { isLoading: isUpdating, isError, error, mutate } = useUpdateCustomer()

  const handleSubmit = (values) => {
    // Send DOB in date format.
    if (values.dob !== null && !(values.dob instanceof Date)) {
      values.dob = DateTime.fromObject(values.dob)
    }

    mutate([client.id, values], {
      onSuccess: () => {
        setShow(false)
        notification.notify(
          <Notification
            title="Details updated"
            description={`Details for ${client.full_name} were successfully saved.`}
            variant="success"
            autoDismiss
          />
        )
      },
    })
  }

  const validationSchema = object({
    first_name: string().max(255, "First name can't be longer than 255 characters"),
    last_name: string().max(255, "Last name can't be longer than 255 characters"),
    email: string().email('Please enter a valid email address'),
    phone: string().nullable(),
    dob: object()
      .nullable()
      .test('valid-date', 'Date of birth must be a valid date', (val) => {
        if (val === null) {
          return true
        }

        try {
          const date = DateTime.fromObject(val)

          return date.isValid
        } catch (e) {
          return false
        }
      }),
  })

  return (
    <>
      <Button
        label="Edit"
        disabled={client.locked_at}
        leadingIcon={PencilIcon}
        onClick={() => setShow(true)}
      />
      <Modal title="Edit details" isOpen={show} onClose={() => setShow(false)}>
        <Formik initialValues={client} onSubmit={handleSubmit} validationSchema={validationSchema}>
          {({ values, setFieldValue, submitForm }) => (
            <Form>
              {isError && (
                <ErrorProvider error={error}>
                  <ErrorAlert />
                </ErrorProvider>
              )}

              <div className="grid grid-cols-2 gap-6 mt-2">
                <ConnectedField component={Input} label="First name" name="first_name" />
                <ConnectedField component={Input} label="Last name" name="last_name" />
                <div className="col-span-2">
                  <ConnectedField component={Input} label="Email" name="email" type="email" />
                </div>
                <div className="col-span-2">
                  <Label htmlFor="phone" text="Phone number" />
                  <Field name="phone">
                    {({ field, form }: FieldProps) => (
                      <div className="mt-1">
                        <BasePhoneInput
                          {...field}
                          inputComponent={Input}
                          className={'react-phone-input'}
                          value={field.value}
                          onChange={(value) => form.setFieldValue(field.name, value ?? '')}
                          defaultCountry={countryCode}
                        />
                      </div>
                    )}
                  </Field>
                  <ErrorMessage name="phone" />
                </div>
                <div className="col-span-2">
                  <Label text="Date of birth" htmlFor="dob" />
                  <div className="mt-2">
                    <DateOfBirth
                      value={
                        values.dob instanceof Date ? DateTime.fromJSDate(values.dob) : values.dob
                      }
                      onChange={(dob) => setFieldValue('dob', dob)}
                      label="Date of birth"
                      name="dob"
                    />
                  </div>

                  <ErrorMessage name="dob" />
                </div>
                <div>
                  <LocaleSelect
                    label="Preferred language"
                    selectedByCode={values.preferred_locale}
                    onChange={(val) => setFieldValue('preferred_locale', val.value)}
                  />
                </div>
              </div>
              <ModalButtons>
                <Button
                  variant="primary"
                  label="Save changes"
                  onClick={submitForm}
                  loading={isUpdating}
                />
                <Button label="Cancel" onClick={() => setShow(false)} />
              </ModalButtons>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  )
}
