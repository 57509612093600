import { useInfiniteQuery, useQuery, UseQueryOptions } from 'react-query'
import { AuditLogsApi as LegacyAuditLogsApi } from '@trybeapp/sdk.shop'
import { usePageQueryParam } from 'hooks/UsePageQueryParam'
import { AuditLogsApi, ListAuditLogs, ListAuditLogsRequest } from '@trybeapp/sdk'
import { apiConfiguration, formatDateForApi } from 'utilities/TrybeApiClient'

const api = new LegacyAuditLogsApi()
const newApi = new AuditLogsApi(apiConfiguration)

export const useGetAuditLogs = (subjectId: string) => {
  const [page] = usePageQueryParam()
  const params = { subjectId, page }

  return useQuery(['auditLogs', params], fetchAuditLogs)
}

export const useGlobalAuditLog = (
  params: ListAuditLogsRequest,
  options: UseQueryOptions<ListAuditLogs> = {}
) => {
  const [page] = usePageQueryParam()

  return useQuery(
    ['globalAuditLog', { page, ...params }],
    () =>
      newApi.listAuditLogs({
        ...params,
        dateFrom: formatDateForApi(params.dateFrom),
        dateTo: formatDateForApi(params.dateTo),
      }),
    options
  )
}

export const useInfiniteAuditLogs = (subjectId: string, options = {}) => {
  return useInfiniteQuery(['auditLogs', { subjectId }], fetchAuditLogs, {
    getNextPageParam: (lastPage: any) => {
      return lastPage.nextPage
    },
    ...options,
  })
}

const fetchAuditLogs = async ({ queryKey, pageParam: page = 1 }) => {
  const params = queryKey[1]
  const response = await api.auditLogsIndex({ page, ...params })

  return {
    data: response.data,
    meta: response.meta,
    page,
    nextPage: response.meta.last_page > page ? page + 1 : undefined,
    total: response.meta.total,
  }
}
