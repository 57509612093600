import { Modal, ModalButtons } from 'ui/Modal'
import { Label } from 'ui/components/Form/Label'
import { ResourceListItem } from 'ui/components/ResourceListItem'
import { PaginatedMiniList } from 'ui/components/PaginatedMiniList'
import { DropdownDivider, DropdownItem } from 'ui/components/Dropdown'
import { ExternalLinkIcon, SearchIcon, UserAddIcon, UserRemoveIcon } from '@heroicons/react/solid'
import {
  useAddMembershipMember,
  useMembershipMembers,
  useRemoveMembershipMember,
} from 'api/MembershipMembers'
import { Input } from 'ui/components/Form/Input'
import { useEffect, useState } from 'react'
import { Button } from 'ui/components/Button'
import { useCustomers } from 'api/Customers'
import useDebounce from 'hooks/Debounce'
import {
  ApiErrorNotification,
  Notification,
  useNotificationContext,
} from 'ui/components/Notification'
import { getInitials } from 'utilities/initials'
import { Badge } from 'ui/components/Badge'
import { useLocation, useParams } from 'react-router-dom'
import { useSiteConfig } from 'hooks/UseSiteConfig'
import { Text } from 'ui/components/Text'

const AddNewMember = ({ membershipId, existingMembers, onAdded }) => {
  const [searchQuery, setSearchQuery] = useState('')
  const debouncedSearchQuery = useDebounce(searchQuery, 500)
  const notifications = useNotificationContext()
  const [page, setPage] = useState(1)
  const { site = {} } = useSiteConfig()
  const { brand_id: brandId } = site

  const { isLoading: isLoadingCustomers, data: { data: customers = [], meta } = {} } = useCustomers(
    {
      page,
      query: debouncedSearchQuery,
      brandId,
    }
  )

  const { mutate } = useAddMembershipMember(membershipId)

  const handleAddMember = (customer) => {
    mutate(customer.id, {
      onSuccess: () => {
        notifications.notify(
          <Notification
            title="Member added"
            description={`${customer.full_name} has been added to this membership`}
            variant="success"
            autoDismiss
          />
        )

        onAdded()
      },
      onError: (e: any) => {
        notifications.notify(<ApiErrorNotification error={e} />)
      },
    })
  }

  return (
    <div className="space-y-4">
      <Text type="caption">Pick the customer you want to add to this membership</Text>

      <Input
        value={searchQuery}
        prefixIcon={SearchIcon}
        onChange={(e) => setSearchQuery(e.target.value)}
        placeholder="Search for a customer to add"
      />

      <PaginatedMiniList
        isLoading={
          isLoadingCustomers || (searchQuery.length >= 3 && debouncedSearchQuery.length < 3)
        }
        emptyMessage={'No customers found'}
        meta={meta}
        onPageChange={setPage}
        rows={customers.map((customer) => {
          const member = existingMembers.find((member) => member.customer.id === customer.id)

          return (
            <ResourceListItem
              key={customer.id}
              compact
              title={customer.full_name}
              subtitle={customer.email}
              showDisclosureArrow={!member}
              disabled={member}
              badge={
                member ? (
                  <Badge label={member.is_lead ? 'Lead member' : 'Member'} variant="info" />
                ) : null
              }
              onClick={() => handleAddMember(customer)}
            />
          )
        })}
      />
    </div>
  )
}
export const ManageMembersModal = ({ isOpen, onClose, membership }) => {
  const [isAddingNewMember, setAddingNewMember] = useState(false)
  const notifications = useNotificationContext()
  const path = useLocation().pathname
  const { clientId } = useParams()

  useEffect(() => {
    onClose()
  }, [path])

  const {
    isLoading,
    isError,
    data: { data: members = [] } = {},
  } = useMembershipMembers(membership?.id, {
    enabled: membership?.id !== undefined,
  })

  const { mutate: removeMember } = useRemoveMembershipMember(membership?.id)

  const handleRemoveMember = (member) => {
    removeMember(member.customer.id, {
      onSuccess: () => {
        notifications.notify(
          <Notification
            title="Member removed"
            description={`${member.customer.full_name} has been removed from this membership`}
            variant="success"
            autoDismiss
          />
        )
      },
      onError: (e: any) => {
        notifications.notify(<ApiErrorNotification error={e} />)
      },
    })
  }

  const handleClose = () => {
    setAddingNewMember(false)
    onClose()
  }

  return (
    <Modal title="Manage members" isOpen={isOpen} onClose={handleClose}>
      {membership?.customer && !isAddingNewMember && (
        <div className="space-y-4">
          <div className="space-y-2">
            <Label>Lead member</Label>
            <PaginatedMiniList
              rows={[
                <ResourceListItem
                  key={membership.customer.id}
                  compact
                  title={membership.customer.full_name}
                  subtitle={membership.customer.email}
                  avatar={{
                    url: membership.customer.avatar?.url,
                    initials: getInitials(membership.customer.full_name),
                  }}
                  dropdownAboveModal
                  dropdownItems={
                    membership.customer.id !== clientId
                      ? [
                          <DropdownItem
                            label="Go to client"
                            icon={ExternalLinkIcon}
                            inAppLink
                            href={`/clients/${membership.customer.id}`}
                          />,
                        ]
                      : []
                  }
                />,
              ]}
            />
            <Text type="caption">
              The lead member is the member responsible for payment of this membership.
            </Text>
          </div>
          <div className="space-y-2">
            <div className="flex justify-between items-center">
              <Label>Additional members</Label>

              <Button
                label="Add member"
                leadingIcon={UserAddIcon}
                size="xs"
                variant="primary"
                disabled={isLoading || isError || members.length >= membership.type.max_members}
                onClick={() => setAddingNewMember(true)}
              />
            </div>
            <PaginatedMiniList
              isLoading={isLoading}
              emptyMessage="No additional members"
              rows={members
                .filter((member) => !member.is_lead)
                .map((member) => (
                  <ResourceListItem
                    key={member.customer.id}
                    compact
                    avatar={{
                      url: member.customer.avatar?.url,
                      initials: getInitials(member.customer.full_name),
                    }}
                    title={member.customer.full_name}
                    subtitle={member.customer.email}
                    dropdownAboveModal
                    dropdownItems={
                      member.customer.id !== clientId
                        ? [
                            <DropdownItem
                              icon={ExternalLinkIcon}
                              label="Go to client"
                              inAppLink
                              href={`/clients/${member.customer.id}`}
                            />,
                            <DropdownDivider />,
                            <DropdownItem
                              icon={UserRemoveIcon}
                              label="Remove"
                              onClick={() => handleRemoveMember(member)}
                            />,
                          ]
                        : []
                    }
                  />
                ))}
            />
          </div>
          {members.length >= membership.type.max_members && (
            <Text type="caption">
              You can't add any more members as you've reached the maximum allowed for{' '}
              <span className="font-medium">{membership.type.name}</span>
            </Text>
          )}
        </div>
      )}

      {isAddingNewMember && (
        <AddNewMember
          existingMembers={members}
          membershipId={membership?.id}
          onAdded={() => setAddingNewMember(false)}
        />
      )}

      <ModalButtons>
        {!isAddingNewMember && <Button label="Done" onClick={handleClose} />}

        {isAddingNewMember && (
          <Button label="Back to members" onClick={() => setAddingNewMember(false)} />
        )}
      </ModalButtons>
    </Modal>
  )
}
