import { Modal, ModalButtons } from 'ui/Modal'
import { Input } from 'ui/components/Form/Input'
import { Button } from 'ui/components/Button'
import { Form, Formik, Field, FieldProps } from 'formik'
import { useCreateCustomer } from 'api/Customers'
import { useSiteConfig } from 'hooks/UseSiteConfig'
import {
  useNotificationContext,
  Notification,
  ApiErrorNotification,
} from 'ui/components/Notification'
import { ConnectedField as BaseConnectedField } from 'components/ConnectedField'
import { object, string } from 'yup'
import { useHistory } from 'react-router-dom'
import { default as BasePhoneInput } from 'react-phone-number-input'
import { Label } from 'ui/components/Form/Label'
import { ErrorMessage } from 'ui/components/Form/ErrorMessage'
import { Text } from 'ui/components/Text'

const ConnectedField = BaseConnectedField as any

export const AddClientOverlay = ({
  onCancel,
  isOpen = false,
  onCreate,
  redirectOnCreate = true,
  values = {},
}: any) => {
  const { siteId, site: { brand_id: activeBrandId = '', country_code: countryCode = '' } = {} } =
    useSiteConfig()
  const { isLoading, mutate } = useCreateCustomer(siteId)
  const notification = useNotificationContext()
  const history = useHistory()

  const initialValues = {
    first_name: values.first_name ?? '',
    last_name: values.last_name ?? '',
    email: values.email ?? '',
    phone: values.phone ?? '',
    brand_id: activeBrandId,
  }

  const validationSchema = object({
    first_name: string().required('First name is required'),
    last_name: string().required('Last name is required'),
    email: string().email(),
  })

  const handleSubmit = (values) => {
    const newValues = { ...values }

    if (newValues.phone === '') {
      delete newValues.phone
    }

    if (newValues.email === '') {
      delete newValues.email
    }

    mutate(newValues, {
      onSuccess: ({ data }: any) => {
        if (typeof onCreate === 'function') {
          onCreate(data)
        }

        if (redirectOnCreate) {
          history.push(`/clients/${data.id}`)
        }

        notification.notify(
          <Notification
            title="Client created"
            description="The client was successfully created"
            variant="success"
            autoDismiss
          />
        )
        onCancel()
      },
      onError: (error: any) => {
        notification.notify(<ApiErrorNotification error={error} />)
      },
    })
  }

  return (
    <Modal title="Create new client" isOpen={isOpen} onClose={onCancel} size="sm">
      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        <Form>
          <Text type="caption">Provide some basic details about the new client.</Text>

          <div className="mt-6 grid grid-cols-1 gap-y-4 gap-x-4">
            <div>
              <ConnectedField name="first_name" component={Input} label="First name" required />
            </div>
            <div>
              <ConnectedField name="last_name" component={Input} label="Last name" required />
            </div>

            <div>
              <ConnectedField type="email" name="email" component={Input} label="Email address" />
            </div>
            <div>
              <Label htmlFor="phone" text="Phone number" />
              <Field name="phone">
                {({ field, form }: FieldProps) => (
                  <div className="mt-1">
                    <BasePhoneInput
                      {...field}
                      inputComponent={Input}
                      className={'react-phone-input'}
                      value={field.value}
                      onChange={(value) => form.setFieldValue(field.name, value)}
                      defaultCountry={countryCode}
                    />
                  </div>
                )}
              </Field>
              <ErrorMessage name="phone" />
            </div>
          </div>

          <ModalButtons>
            <Button variant="primary" type="submit" label="Create" loading={isLoading} />
            <Button label="Cancel" onClick={onCancel} />
          </ModalButtons>
        </Form>
      </Formik>
    </Modal>
  )
}
