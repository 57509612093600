import { ShoppingBagIcon } from '@heroicons/react/outline'
import { ChevronRightIcon, PlusIcon } from '@heroicons/react/solid'
import { ChevronRightIcon as ChevronRightIconOutline } from '@heroicons/react/outline'
import { useGetCustomer, useGetCustomerStats } from 'api/Customers'
import { useAddOrder, useGetOrders } from 'api/Orders'
import { useParams, useHistory } from 'react-router-dom'
import { Button } from 'ui/components/Button'
import { EmptyState } from 'ui/components/EmptyState'
import { Section } from '../Shared/Section'
import { DateTime } from 'luxon'
import { LinkWithPreload } from 'utilities/PreloadLinks'
import { StatusTag } from 'screens/OrderDetails/components/Overview/Overview'
import { useActiveFeatureFlags } from 'contexts/SiteConfig'
import pluralize from 'pluralize'
import { useAnalytics } from 'utilities/analytics'

const CreateOrderButton = () => {
  const { clientId } = useParams()
  const history = useHistory()
  const { isLoading, mutateAsync } = useAddOrder()
  const { fireEvent } = useAnalytics()

  const handleNewOrder = async () => {
    fireEvent('order_created', { via: 'client_details' })

    const { data: order } = await mutateAsync({
      customer_id: clientId,
    } as any)

    history.push(`/orders/${order.id}`)
  }

  return <Button label="New" loading={isLoading} leadingIcon={PlusIcon} onClick={handleNewOrder} />
}

const Order = ({ order }) => {
  // TEMP: if the organisation_reports feature flag is enabled we'll have been given an order summary
  // which includes num_items. Getting items like this can be removed when that flag is tidied up.
  const items = [
    ...(order?.booking_items ?? []),
    ...(order?.package_items ?? []),
    ...(order.purchase_items ?? []),
  ]

  const numItems = order.num_items ?? items.length

  return (
    <LinkWithPreload
      className="flex justify-between items-center p-3 hover:bg-violet-50 w-full"
      to={`/orders/${order.id}`}
    >
      <div>
        <div className="flex space-x-1 items-center">
          <div className="font-medium">
            {order.order_ref ?? <span className="italic">No ref</span>}
          </div>
          <StatusTag status={order.status} />
        </div>
        <div className="text-gray-500 truncate">
          {order.site && (
            <>
              <span>{order.site.name}</span>
              <span> &middot; </span>
            </>
          )}
          <span>
            Received {DateTime.fromJSDate(order.submitted_at).toLocaleString(DateTime.DATETIME_MED)}
          </span>
          <span> &middot; </span>
          <span>
            {numItems} {pluralize('item', numItems)}
          </span>
        </div>
      </div>
      <div>
        <ChevronRightIconOutline className="w-6 h-6 text-gray-400" />
      </div>
    </LinkWithPreload>
  )
}

export const Orders = () => {
  const { clientId } = useParams()
  const featureFlags = useActiveFeatureFlags()
  const orgReportsEnabled = featureFlags.includes('organisation_reports')

  const { isLoading: isLoadingClient, data: { data: client = {} } = {} } = useGetCustomer(clientId)

  const { isLoading: isLoadingOrders, data: { data: orders = [], meta = {} } = {} } = useGetOrders(
    {
      customerId: clientId,
      perPage: 5,
    },
    { enabled: !orgReportsEnabled }
  )

  const {
    isLoading: isLoadingStats,
    data: { data: { totals = {}, recent_orders: statsOrders = [] } = {} } = {},
  } = useGetCustomerStats(clientId, {
    enabled: orgReportsEnabled,
  })

  const displayOrders = orgReportsEnabled ? statsOrders.slice(0, 5) : orders
  const numOrders = orgReportsEnabled ? totals.num_orders : meta.total
  const isLoading = orgReportsEnabled ? isLoadingStats : isLoadingOrders

  return (
    <Section
      title="Orders"
      button={!isLoadingClient && client.deleted_at === null && <CreateOrderButton />}
    >
      {isLoading && <div className="bg-grape-100 animate-pulse h-16" />}

      {!isLoading && displayOrders.length === 0 && (
        <EmptyState title="This client has no orders" icon={ShoppingBagIcon} />
      )}

      {!isLoading && displayOrders.length > 0 && (
        <ul className="text-sm divide-y divide-violet-100">
          {displayOrders.map((order) => (
            <Order key={order.id} order={order} />
          ))}
        </ul>
      )}

      {!isLoading && numOrders > 5 && (
        <LinkWithPreload
          to={`/orders?customer_id=${clientId}`}
          className="text-violet ml-3 font-medium hover:underline mt-4 text-sm flex space-x-1"
        >
          <span>View more</span>
          <ChevronRightIcon className="w-4 h-4" aria-hidden="true" />
        </LinkWithPreload>
      )}
    </Section>
  )
}
