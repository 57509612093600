import { Modal, ModalButtons } from 'ui/Modal'
import { Button } from 'ui/components/Button'
import { useParams } from 'react-router-dom'
import {
  ApiErrorNotification,
  Notification,
  useNotificationContext,
} from 'ui/components/Notification'
import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useGlobalBarcodeListenerContext } from 'components/BarcodeListener/GlobalContext'
import { BarcodeListener } from 'components/BarcodeListener'
import QRCode from 'qrcode'
import {
  useAssociateBarcode,
  useAssociatedBarcodesForClient,
  useDisassociateBarcode,
  useGetCustomer,
} from 'api/Customers'
import { DateTime } from 'luxon'
import { Dropdown, DropdownItem } from 'ui/components/Dropdown'
import { DocumentDownloadIcon, PrinterIcon, XIcon } from '@heroicons/react/solid'
import { PaginatedMiniList } from 'ui/components/PaginatedMiniList'
import { ResourceListItem } from 'ui/components/ResourceListItem'
import { Spinner } from 'ui/components/Spinner'
import { useReactToPrint } from 'react-to-print'
import { ClientAvatar } from './ClientAvatar'
import { Input } from 'ui/components/Form/Input'

const DefaultBarcodeCard = () => {
  const { clientId } = useParams()
  const { isLoading, data: { data: customer } = {} } = useGetCustomer(clientId)

  const [canvas, setCanvas] = useState(null)
  const printRef = useRef()

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: `Temporary membership pass for ${customer.full_name}`,
    pageStyle: `
      @page {
        size: 85mm 50mm;
        margin: 0;
      }
    `,
  })

  const handleDownload = async () => {
    const file = await QRCode.toDataURL(`TRYBM-${clientId}`)

    // Now make an anchor tag so we can programatically click it.
    const elm = document.createElement('a')
    elm.setAttribute('href', file)
    elm.setAttribute('download', `${customer.full_name.replace(' ', '-').toLowerCase()}-qrcode.png`)
    elm.style.display = 'none'
    document.body.appendChild(elm)
    elm.click()
    document.body.removeChild(elm)
  }

  useLayoutEffect(() => {
    if (canvas) {
      QRCode.toCanvas(canvas, `TRYBM-${clientId}`, {
        margin: 0,
        scale: 3,
      })
    }
  }, [canvas, clientId])

  return (
    <>
      <div className="text-gray-500 font-medium mb-1 text-sm">Default pass</div>
      <div className="p-3 bg-violet-50 aspect-[85/50] shadow-sm rounded-md mt-1 mb-4">
        <div className="h-full relative">
          <div ref={printRef} className="print:p-3 grid grid-cols-3 h-full">
            <div className="col-span-2 flex flex-col justify-between">
              <div className="font-semibold text-xl p-3">{customer.full_name}</div>

              <div className="">
                <ClientAvatar avatarUrl={customer.avatar ? customer.avatar.url : null} />
              </div>
            </div>
            <div className="col-span-1 flex items-end">
              <canvas
                ref={setCanvas}
                className="bg-white p-1 px-2 rounded shadow-sm print:shadow-none ml-auto"
              />
            </div>
          </div>

          <div className="absolute top-0 right-0 m-1">
            <Dropdown threeDots aboveModal>
              <DropdownItem
                icon={PrinterIcon}
                label="Print"
                onClick={handlePrint}
                disabled={isLoading}
              />
              <DropdownItem icon={DocumentDownloadIcon} label="Download" onClick={handleDownload} />
            </Dropdown>
          </div>
        </div>
      </div>
    </>
  )
}

const ManageBarcodeScreen = () => {
  const { clientId } = useParams()
  const notification = useNotificationContext()
  const { isLoading: isLoadingAssociatedBarcodes, data: { data: associatedBarcodes = [] } = {} } =
    useAssociatedBarcodesForClient(clientId)

  const { mutate: dissociate, isLoading: isDisassociating } = useDisassociateBarcode()

  const handleDissociate = (barcode) => {
    dissociate([clientId, barcode.id], {
      onSuccess: () => {
        notification.notify(
          <Notification
            title="Pass dissociated"
            description="The pass has been dissociated from the customer"
            variant="success"
            autoDismiss
          />
        )
      },
      onError: (e: any) => {
        notification.notify(<ApiErrorNotification error={e} />)
      },
    })
  }

  return (
    <>
      <DefaultBarcodeCard />

      <div className="text-gray-500 font-medium mb-1 text-sm">Custom associated passes</div>

      <p className="text-sm text-gray-500 mb-2">
        If you have an existing pass for this client, such as a physical membership card, you can
        associate it with this client.
      </p>

      <PaginatedMiniList
        isLoading={isLoadingAssociatedBarcodes}
        emptyMessage="No associated passes"
        rows={associatedBarcodes.map((barcode) => (
          <ResourceListItem
            compact
            key={barcode.id}
            title={barcode.barcode}
            subtitle={`Associated ${DateTime.fromJSDate(barcode.created_at).toLocaleString(
              DateTime.DATETIME_MED
            )}`}
            dropdownAboveModal
            dropdownItems={[
              <DropdownItem
                label={isDisassociating ? 'Dissociating...' : 'Dissociate'}
                onClick={() => handleDissociate(barcode)}
                disabled={isDisassociating}
                icon={XIcon}
              />,
            ]}
          />
        ))}
      />
    </>
  )
}

const AssociateNewBarcodeScreen = ({ onAssociated, onBack }) => {
  const { clientId } = useParams()
  const notification = useNotificationContext()
  const { setActive, prefixes, suffixes } = useGlobalBarcodeListenerContext()
  const { isLoading, mutate } = useAssociateBarcode()
  const [manualEntry, setManualEntry] = useState('')

  useEffect(() => {
    // Whenever this component is mounted, disable the global barcode
    // listener so we can override the behaviour.
    setActive(false)

    return () => {
      setActive(true)
    }
  })

  const onAssociate = (code) => {
    if (code.length < 4) {
      notification.notify(
        <Notification
          title="Code too short"
          description="The pass must be at least 4 characters"
          variant="danger"
          autoDismiss
        />
      )

      return
    }

    mutate([clientId, code], {
      onSuccess: () => {
        onAssociated()

        notification.notify(
          <Notification
            title="Pass associated"
            description="The pass has been associated with this client."
            variant="success"
            autoDismiss
          />
        )
      },
      onError: (e: any) => {
        notification.notify(<ApiErrorNotification error={e} />)
      },
    })
  }

  return (
    <>
      <div
        className={`${
          !isLoading ? 'animate-pulse' : ''
        } w-full h-32 rounded-md bg-gray-200 flex justify-center items-center text-sm font-medium relative`}
      >
        {!isLoading && (
          <div className="text-center">
            <div>Awaiting input</div>
            <div className="font-normal text-xs mt-1">
              Scan, swipe or tap the pass using your connected reader.
            </div>
          </div>
        )}

        {isLoading && (
          <div className="text-white text-center">
            <Spinner />
          </div>
        )}
      </div>

      <div className="my-4 text-center text-gray-500 text-xs font-medium">or</div>
      <Input
        label="Manually enter code"
        hideLabel
        placeholder="Manually enter code"
        onChange={(e) => setManualEntry(e.target.value)}
        value={manualEntry}
        className="flex-1 w-full"
      />

      <input
        type="text"
        className="opacity-0 absolute top-0 left-0"
        autoFocus
        onInput={(e) => e.preventDefault()}
      />

      <BarcodeListener prefixCodes={prefixes} suffixCodes={suffixes} onScan={onAssociate} />

      <ModalButtons>
        <Button label="Associate" variant="primary" onClick={() => onAssociate(manualEntry)} />
        <Button label="Back" onClick={() => onBack()} />
      </ModalButtons>
    </>
  )
}

export const ManageBarcodeModal = ({ onClose, isOpen }) => {
  const [screen, setScreen] = useState(0)
  const [hasAssociated, setHasAssociated] = useState(false)

  const handleAssociated = () => {
    setScreen(0)
    setHasAssociated(true)
  }

  useEffect(() => {
    return () => {
      setScreen(0)
      setHasAssociated(false)
    }
  }, [])

  return (
    <Modal
      isOpen={isOpen}
      showHideIcon={false}
      onClose={() => {}} // Don't do anything to fix bug with nested dropdowns
      title="Manage passes"
      size="sm"
    >
      {screen === 0 && (
        <>
          <ManageBarcodeScreen />
          <ModalButtons>
            <Button
              label={`${hasAssociated ? 'Associate another pass' : 'Associate new pass'}`}
              onClick={() => setScreen(1)}
              variant="primary"
            />
            <Button label={`${hasAssociated ? 'Done' : 'Close'}`} onClick={onClose} />
          </ModalButtons>
        </>
      )}

      {screen === 1 && (
        <>
          <AssociateNewBarcodeScreen onAssociated={handleAssociated} onBack={() => setScreen(0)} />
        </>
      )}
    </Modal>
  )
}
