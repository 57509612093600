import { useUpdateCustomerAddress } from 'api/Customers'
import { ErrorAlert } from 'components/ErrorAlert'
import { ErrorProvider } from 'contexts/Error'
import { Form, Formik } from 'formik'
import { Button } from 'ui/components/Button'
import { Modal, ModalButtons } from 'ui/Modal'
import { AddressForm } from './AddressForm'
import { useParams } from 'react-router-dom'
import { Notification, useNotificationContext } from 'ui/components/Notification'

export const EditAddressModal = ({ onClose, address }) => {
  const { clientId } = useParams()
  const notification = useNotificationContext()

  const { isLoading, isError, error, mutate } = useUpdateCustomerAddress(clientId)

  const handleSave = (vals) => {
    mutate([address.id, vals], {
      onSuccess: () => {
        onClose()

        notification.notify(
          <Notification
            title="Address updated"
            description={`The address was successfully updated`}
            variant="success"
            autoDismiss
          />
        )
      },
    })
  }

  return (
    <Modal title="Edit address" isOpen={address !== null} onClose={() => onClose()}>
      <Formik initialValues={{ ...address }} enableReinitialize onSubmit={handleSave}>
        {({ submitForm }) => (
          <Form>
            {isError && (
              <ErrorProvider error={error}>
                <ErrorAlert />
              </ErrorProvider>
            )}

            <AddressForm />

            <ModalButtons>
              <Button
                variant="primary"
                label="Save changes"
                onClick={submitForm}
                loading={isLoading}
              />
              <Button label="Cancel" onClick={() => onClose()} />
            </ModalButtons>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}
